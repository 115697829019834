import AreaButton from 'components/ui/area-button/area-button';
import Button from 'components/ui/button/button';
import Info from 'components/ui/info/info';
import Logo from 'components/ui/logo/logo';
import InfoBar from 'components/ui/info-bar/info-bar';
import { gameUiTexts } from 'data/ui-texts';
import { calculateCompletionData, getGroupPoints } from 'helpers/game-helper';
import React from 'react';
import PropTypes from 'prop-types';
import './area.scss';
import { gamesData } from 'data/games-data';

const Area = ({ group, infoTitle, infoText, routes, id, setCasesId, setPageId, gameId}) => {
	const groupName = group.name ? group.name : group.id;
	const groupPoints = getGroupPoints(group);

	return (
		<div className='Area'>
			<div className='Area-header'>
				<div className='Area-logo'><Logo gameId={gameId}/></div>
				<InfoBar points={groupPoints} iconId='trophy' gameId={gameId}/>
				<div className='Area-groupName'>
					<InfoBar text={groupName} iconId='person' gameId={gameId}/>
				</div>	
			</div>

			<div className='Area-contentWrapper'> 
				<div className='Area-sideWrapper'>
					<div className='Area-infoWindow'>
						{(infoTitle && infoText) && 
							<Info 
								title={infoTitle} 
								text={infoText}
								gameId={gameId}
							/>
						}
					</div>
				</div>
				<div className='Area-middleWrapper'>
					<div className='Area-areaWrapper'>
						<div className='Area-lines'>
							{routes.map((route, index) => {
								const caseTasksData = gamesData[gameId].tasksData[id].filter((c) => {
									return c.caseId === route.id;
								});
								/* Do not show lines for cases with no tasks */
								if (caseTasksData.length === 0) return null;
								
								return <div key={index} className='Area-line' />;
							})}
						</div>
						<div className={'Area-areaButton ' + id}>
							<AreaButton
								areaButtonId={id}
								name={infoTitle} 
								onClick={() => {setPageId('areaHub');}}
								gameId={gameId}
							/>
						</div>	
						<div className='Area-cases'>
							{routes.map((route, index) => {
								const caseTasksData = gamesData[gameId].tasksData[id].filter((c) => {
									return c.caseId === route.id;
								});
								/* Do not show cases with no tasks */
								if (caseTasksData.length === 0) return null;

								const completionData = calculateCompletionData(caseTasksData, group.assignments);
								const style = {width: completionData.completedPercentage + '%'};
								return (
									<div key={index} className='Area-casesButton'>
										<div className="Area-casesLine" />
										<Button
											classes={['blueLight', gameId, 'responsive', 'noScaling', 'padded']} 
											text={route.title + ': ' + route.subtitle}
											onClick={() => {setCasesId(route.id);}}
										/>
										<div className={'Area-completionIndicator ' + gameId}>
											<div 
												className={
													'Area-completionIndicatorFill ' + gameId +
													(completionData.completedPercentage === 100 ? ' rounded' : '')
												}
												style={style}/>
											<span>{completionData.completionCount + '/' + caseTasksData.length}</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="Area-backBtn">
				<Button 
					text={gameUiTexts.backBtn}
					classes={['backBtnGame', gameId, 'responsive']}
					onClick={() => {setPageId('areaHub');}}
				/>
			</div>
		</div>
	);
};

Area.propTypes = {
	infoTitle: PropTypes.string.isRequired,
	infoText: PropTypes.string.isRequired,
	routes: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	setCasesId: PropTypes.func.isRequired,
	setPageId: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default Area;