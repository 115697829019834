import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './order-dnd-item.scss';

const OrderDnDItem = (props) => {
	const {
		isDroppable,
		isDraggable, 
		layout,
		location, 
		positionNumber, 
		itemData, 
		classes, 
		handleMoveItem,
		gameId
	} = props;

	

	/* Drag functionality */
	const [{isDragging}, drag, preview] = useDrag({
		type: 'item',
		item: {type: 'item', id: (itemData ? itemData.id : null), location, positionNumber},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return ((itemData && itemData.id === monitor.getItem().id));},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	/* Drop functionality */
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (!isDroppable) return;

			if (handleMoveItem) {				
				/* Ignore hovering over itself */
				if (
					item.location === location &&  
					item.positionNumber === positionNumber
				) return;

				/* Move card */
				handleMoveItem(item.id, location, positionNumber);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: isDroppable
			};
		}
	});

	/* Opacity (invisible if dragging) */
	const opacity = (isDragging ? 0 : 1);

	/* Class name */
	let className = 'OrderDnDItem ' + gameId + ' ' + (layout ? ' ' + layout : '');
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}
	if (!isDraggable) className += ' disabled';	
	if  (isOver && canDrop) className += ' hover';

	const ref = useRef(null);
	const dragDropRef = drag(drop(ref));
	
	return (
		<div
			ref={dragDropRef}
			className={className}
			style={{opacity}}
		>
			{(itemData && itemData.text) && 
				<span>{itemData.text}</span>
			}
		</div>
	);
};

OrderDnDItem.propTypes = {
	isDroppable: PropTypes.bool.isRequired,
	isDraggable: PropTypes.bool.isRequired,
	layout: PropTypes.string,
	location: PropTypes.string.isRequired,
	positionNumber: PropTypes.number.isRequired,
	itemData: PropTypes.object,
	classes: PropTypes.array,
	handleMoveItem: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired
};

export default OrderDnDItem;
