import React from 'react';
import PropTypes from 'prop-types';
import { loginUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './login-facilitator.scss';

const LoginFacilitator = (props) => {
	let {
		isLoading,
		email,
		password,
		feedback,
		handleInput,
		handleLogin,
		setFacilitatorBoxType,
		gameId
	} = props;

	return (
		<div className={'LoginFacilitator ' + gameId}>
			<div className="LoginFacilitator-title"><span>{loginUiTexts.facilitatorLogin}</span></div>
			<div className="LoginFacilitator-body">
			
				<div className='LoginFacilitator-loginDirection'><span>{loginUiTexts.login + ':'}</span></div>
				<form className="LoginFacilitator-form" onSubmit={(e) => {handleLogin(e);}}>
					<input
						name="email"
						type="text"
						placeholder={loginUiTexts.email} 
						autoComplete="section-facilitatorlogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={loginUiTexts.password}
						autoComplete="section-facilitatorlogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<div 
						className="LoginFacilitator-resetPasswordBtn"
						onClick={()=>{setFacilitatorBoxType('resetPassword');}}
					><span>{loginUiTexts.resetPassword}</span></div>
					<div className="LoginFacilitator-loginBtn">
						<Button 
							isLoading={isLoading}
							classes={['login']}
							text={loginUiTexts.login}
							onClick={() => {handleLogin();}}
							type='submit'
						/>
					</div>
				</form>
				<div 
					className="LoginFacilitator-createUserBtn"
					onClick={()=>{setFacilitatorBoxType('createUser');}}
				><span>{loginUiTexts.createNewUser}</span></div>
				<div className="LoginFacilitator-errorMessage">{feedback}</div> 
			</div>
		</div>
	);
};

LoginFacilitator.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default LoginFacilitator;