import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';
import './popup-delete-user.scss';
import { adminUiTexts } from 'data/ui-texts';

const PopupDeleteUser = ({deleteUser, userData, closePopup}) => {
	return (
		<div onClick={() => {closePopup();}} className='PopupDeleteUser'>
			<div onClick={(e) => {e.stopPropagation();}} className='PopupDeleteUser-dialogue'>
				<div className='PopupDeleteUser-title'>
					<span>{adminUiTexts.deleteUserWarning.title}</span>
				</div>
				<div className='PopupDeleteUser-warning'>
					<span>{adminUiTexts.deleteUserWarning.text + userData.email + '?'}</span>
				</div>
				<div className='PopupDeleteUser-popupButtons'>
					<div className='PopupDeleteUser-confirmBtn'>
						<Button
							text={adminUiTexts.deleteUserWarning.confirmDelete}
							classes={['blueRounded']}
							onClick={() => {
								deleteUser(userData);
								closePopup();
							}}
						/>
					</div>
					<div className='PopupDeleteUser-cancelBtn'>
						<Button
							text={adminUiTexts.deleteUserWarning.cancelDelete}
							classes={['blueRounded']}
							onClick={() => {
								closePopup();
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupDeleteUser.propTypes = {
	deleteUser: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
};

export default PopupDeleteUser;