import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import OrderDndContainer from './order-dnd-container';
import OrderDnDItem from './order-dnd-item';
import OrderDndPreview from './order-dnd-preview';
import './order.scss';

const Order = ({isCompleted, playerTaskData, taskData, handleUpdateTask, handleCompleteTask, gameId}) => {
	/* Timeout */
	let timeout = useRef(null);

	/* Track ordered items and round number */
	const [orderedItems, setOrderedItems] = useState([]);
	const [roundNumber, setRoundNumber] = useState(1);

	/* Game is paused */
	const [isPaused, setIsPaused] = useState(false);

	/* Get items to be ordered */
	const getOrderedItems = () => {
		let items = [];

		if (playerTaskData && playerTaskData.orderedItems) {
			/* Task (partially) solved, get items from player data */	
			items = JSON.parse(JSON.stringify(playerTaskData.orderedItems));
			items.forEach((item, index) => {
				
				if (!item.location) {
					item.location = 'final';
				}
				if (!item.positionNumber) {
					item.positionNumber = index + 1;
				}
			});
		} else {
			/* Get items data file and shuffle */
			items = shuffleArray(taskData.items.map((item) => {return {id: item.id, isLocked: false};}));
			items.forEach((item, index) => {
				item.location = 'initial';
				item.positionNumber = index + 1;
			});
		}
		return items;
	};


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		
		return () => {
			/* Component will unmount */
			if (timeout.current) clearTimeout(timeout.current);
		};
	}, []);

	/* Update sorted items if new task */
	useEffect(() => {
		setOrderedItems(getOrderedItems());
		setRoundNumber(1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Check if item is placed correct
	 * @param {string} itemId 
	 * @returns 
	 */
	const checkIfItemsIsPlacedCorrect = (itemId) => {
		let isPlacedCorrect = false;
		const placedItem = orderedItems.find((i) => {return (i.id === itemId);});
		if (placedItem) {
			const itemData = taskData.items.find((i) => {return i.id === placedItem.id;});
			if (
				itemData && 
				itemData.correctOrderNumbers.indexOf(placedItem.positionNumber) >= 0
			) {
				isPlacedCorrect = true;
			}
		}
		return isPlacedCorrect;
	};

	/**
	 * Switch items
	 * @param {string} itemId 
	 * @param {string} toLocation
	 * @param {number} toPositionNumber 
	 */
	const handleMoveItem = (itemId, toLocation, toPositionNumber) => {
		let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
		
		const fromIndex = orderedItems.findIndex((i) => {return i.id === itemId;});
		const toIndex = orderedItems.findIndex((i) => {
			return (i.location === toLocation && i.positionNumber === toPositionNumber);
		});
		
		// We stop the operation if the final location is occupied by a locked option
		if (toIndex >= 0 && orderedItems[toIndex].isLocked) {
			return;
		}

		/* Move item */
		newOrderedItems[fromIndex].location = toLocation;
		newOrderedItems[fromIndex].positionNumber = toPositionNumber;
		if (toIndex >= 0) {
			/* Switch item */
			newOrderedItems[toIndex].location = orderedItems[fromIndex].location;
			newOrderedItems[toIndex].positionNumber = orderedItems[fromIndex].positionNumber;
		}
		setOrderedItems(newOrderedItems);
	};

	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Count errors */
		let errors = 0;
		orderedItems.forEach((item) => {
			const isPlacedCorrect = checkIfItemsIsPlacedCorrect(item.id);
			if (!isPlacedCorrect) {
				errors += 1;
			}
		});

		if (errors > 0) {
			startNextRound(errors);
		} else {
			/* Complete task */
			completeTask();
		}
	};

	/**
	 * Start next round
	 */
	const startNextRound = (errors) => {
		/* Pause game */
		setIsPaused(true);

		/* Update round number */
		const newRoundNumber = roundNumber + 1;
		setRoundNumber(newRoundNumber);

		/* Lock correctly placed items, move wrongly placed items back */
		let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
		newOrderedItems.forEach((item) => {
			if (checkIfItemsIsPlacedCorrect(item.id)) {
				/* Correct -> lock */
				item.isLocked = true;
			} else {
				/* Wrong -> move */
				item.location = 'initial';
			}
		});
		setOrderedItems(newOrderedItems);

		handleUpdateTask(
			'order', 
			{orderedItems: newOrderedItems, errors: errors}
		);

		/* Un-pause game */
		timeout.current = setTimeout(() => {setIsPaused(false);}, 1500);
	};


	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = (roundNumber - 1);
		
		/* Save completed task */
		handleCompleteTask(
			'order', 
			{orderedItems: orderedItems, errors: errors}
		);
	};

	/**
	 * Checks if there are any unplaced options
	 * @returns boolean
	 */
	const hasUnplacedOptions = () => {
		const unplacedItems = orderedItems.filter((item) => {
			return item.location !== 'final';
		});

		return unplacedItems.length > 0;
	};

	return (
		<div className={'Order' + (taskData.layout ? ' ' + taskData.layout : '')}>
			{taskData.text && <div className="Order-intro">
				{renderMarkdown(taskData.text)}
			</div>}

			<div className={'Order-items size-' + orderedItems.length}>
				{/* Final containers */}
				{[...Array(orderedItems.length)].map((_, index) => {
					/* Check if can be dropped into  */		
					let isDroppable = true;				
					const placedItem = orderedItems.find((i) => {
						return (i.location === 'final' && i.positionNumber === (index + 1));
					});
					if (isCompleted || isPaused || (placedItem && placedItem.isLocked)) {
						isDroppable = false;
					} 
							
					return (
						<div key={index} className={'Order-container final pos-' + (index + 1)}>
							<div className="Order-containerNumber"><span>{(index + 1)}</span></div>
							<OrderDndContainer 
								isDroppable={isDroppable}
								layout={taskData.layout ? taskData.layout : null}
								location="final"
								positionNumber={index + 1}
								handleMoveItem={handleMoveItem}
							/>
						</div>
					);
				})}


				{/* Initial containers */}
				{[...Array(orderedItems.length)].map((_, index) => {
					let isDroppable = (isCompleted || isPaused ? false : true);	
					return (
						<div key={index} className={'Order-element initial pos-' + (index + 1)}>
							<div key={index} className={'Order-container initial pos-' + (index + 1)}>
								<OrderDndContainer 
									isDroppable={isDroppable}
									layout={taskData.layout ? taskData.layout : null}
									location="initial"
									positionNumber={index + 1}
									handleMoveItem={handleMoveItem}
								/>
							</div>
						</div>
					);
				})}

				{/* Items (also work as containers) */}
				{orderedItems.map((item, index) => {
					/* Check if can be dropped into or dragged */					
					let isDroppable = true;	
					let isDraggable = (item.isLocked ? false : true);		

					if (isCompleted || isPaused) {
						isDroppable = false;
						isDraggable = false;
					} 

					/* Get placed item data (if any) */
					const itemData = taskData.items.find((i) => {return i.id === item.id;});

					/* Check if placed item should indicate if it is correct or wrong */
					let classes = [];	
					const isPlacedCorrect = checkIfItemsIsPlacedCorrect(item.id);
					if (isCompleted || isPaused || item.isLocked) {
						if (isPlacedCorrect) {
							classes.push('correct');
						} else {
							classes.push('wrong');
						}
					} 
							
					return (
						<div 
							key={index} 
							className={'Order-item ' + item.location + ' pos-' + item.positionNumber 
								+ (isPaused ? ' animate' : '')}
						>
							<OrderDnDItem 
								isDroppable={isDroppable}
								isDraggable={isDraggable}
								layout={taskData.layout ? taskData.layout : null}
								location={item.location}
								positionNumber={item.positionNumber}
								itemData={itemData}
								classes={classes}
								handleMoveItem={handleMoveItem}
								gameId={gameId}
							/>
						</div>
					);
				})}
				<OrderDndPreview 
					layout={taskData.layout ? taskData.layout : null} 
					itemsData={taskData.items} 
					gameId={gameId}
				/>
			</div>
			
			{/* Done button */}
			{!isCompleted && <div className={'Order-doneBtn size-' + orderedItems.length}>
				<Button
					isDisabled={hasUnplacedOptions()}
					classes={['confirmTask', gameId]}
					text={gameUiTexts.ok}
					onClick={confirmOrder}
				/>
			</div>}
		</div>
	);
};

Order.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleUpdateTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default Order;
