/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyC0kTAnPMUc-It7Td4pOwBxOOZsWPCTdac',
		authDomain: 'cgl-karriereindsatsen-test.firebaseapp.com',
		projectId: 'cgl-karriereindsatsen-test',
		storageBucket: 'cgl-karriereindsatsen-test.appspot.com',
		messagingSenderId: '47659986900',
		appId: '1:47659986900:web:4d324ebd1eea138f743041'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCDYfSYi1pHmflcW-39nub0KqDmeHG8IVc',
		authDomain: 'cgl-karriereindsatsen-pro.firebaseapp.com',
		projectId: 'cgl-karriereindsatsen-pro',
		storageBucket: 'cgl-karriereindsatsen-pro.appspot.com',
		messagingSenderId: '91230862560',
		appId: '1:91230862560:web:deed053b3355dc7f3ea485'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;