import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoginFacilitatorController from './login-facilitator-controller';
import ResetPassword from './reset-password';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import { loginUiTexts, facilitatorUiTexts } from 'data/ui-texts';
import './login.scss';



const Login = ({ gameId }) => {
	/* Get active box component for facilitator box */
	let [facilitatorBoxType, setFacilitatorBoxType] = useState('login');
	let FacilitatorBoxComponent = LoginFacilitatorController;
	if (facilitatorBoxType === 'createUser') FacilitatorBoxComponent = CreateUserController;
	if (facilitatorBoxType === 'resetPassword') FacilitatorBoxComponent = ResetPassword;

	return (
		<div className={'Login ' + gameId}>
			<div className="Login-welcome"><span>{loginUiTexts.welcome}</span></div>
			<div className='Login-logoWrapper'><Logo gameId={gameId}/></div>
			
			<div className="Login-wrap">
				<div className="Login-facilitator">
					<FacilitatorBoxComponent gameId={gameId} setFacilitatorBoxType={setFacilitatorBoxType} />
				</div>
				<div className="Login-group">
					<LoginGroupController gameId={gameId} />
				</div>
			</div>

			<div className="Login-backBtn">
				<Button 
					classes={['backBtnGame', gameId]} 
					text={facilitatorUiTexts.back}
					type="a"
					href="/"
				/>
			</div>
		</div>
	);
};
Login.propTypes = {
	gameId: PropTypes.string.isRequired,
};
export default Login;