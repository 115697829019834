const taskTemplates = require('../../task-templates');

const tasksData = [		
	// case 1
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		subtype: 'images',
		title: 'Opgave 1A',
		taskId: 'design-multiple-choice-designguide-1',
		text: `I skal designe en varedeklaration.
			<br /><br />Hvilken farve skal overskriften have ifølge designguiden?`,
		layout: 'design-designguide',
		image: 'design-product-1',
		imageSolved: 'design-product-2',
		files: [
			{
				id: 'design-guide',
				type: 'image',
				btnText: 'Se designguide',
			}
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'concern', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'design', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'production' 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-purple',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'secondary-lime',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-yellow',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1.3,
		lockedById: 'design-multiple-choice-designguide-1',
		subtype: 'images',
		title: 'Opgave 1B',
		taskId: 'design-multiple-choice-designguide-2',
		text: `I skal designe en varedeklaration.
			<br /><br />Hvilken farve skal indholdsfortegnelsen have ifølge designguiden?`,
		layout: 'design-designguide',
		image: 'design-product-2',
		imageSolved: 'design-product-3',
		files: [
			{
				id: 'design-guide',
				type: 'image',
				btnText: 'Se designguide',
			}
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'production', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'research', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'secondary-lime', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'design',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-purple',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-yellow',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-orange',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1.6,
		lockedById: 'design-multiple-choice-designguide-2',
		subtype: 'images',
		title: 'Opgave 1C',
		taskId: 'design-multiple-choice-designguide-3',
		text: `I skal designe en varedeklaration.
		<br /><br />Hvilke farver skal linksene have ifølge designguiden?`,
		layout: 'design-designguide',
		image: 'design-product-3',
		imageSolved: 'design-product-3-solved',
		files: [
			{
				id: 'design-guide',
				type: 'image',
				btnText: 'Se designguide',
			}
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-yellow', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-orange', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'contrast-red', isCorrect: true 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'design',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'secondary-lime',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'production',
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		taskId: 'design-order-print',
		layout: 'double-height',
		title: 'Opgave 2',
		text: `Nova Medical har fokus på bæredygtighed, og derfor skal varedeklarationerne trykkes på genanvendt papir. Trykkeriet mener ikke, at det er muligt at trykke koncernfarven på det genanvendte papir og foreslår, at farven ændres.
		<br />Diskutér følgende valgmuligheder og placer dem i rækkefølge fra bedst til værst.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'normal-paper', 
				text: `Vi trykker etiketterne på normalt papir, så vi kan følge designguiden.`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'sustainable-paper', 
				text: `Bæredygtighed frem for alt. Vi trykker etiketterne i andre farver.`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'new-design-guide', 
				text: `Vi laver designguiden om og ændrer farver på vores andre produkter.`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'no-print', 
				text: `Vi trykker ikke etiketter til insulinpakkerne.`,
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 3,
		taskId: 'design-sort-colors',
		title: 'Opgave 3',
		text: `I forbindelse med lanceringen af ny medicin skal der designes t-shirts til sælgerne. Vælg de tre farver, som t-shirten skal have ifølge designguiden.	`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'dominating', 
				title: `Den dominerende farve`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'text', 
				title: `Skriften på t-shirten`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'links', 
				title: `Links/SoMe-hashtags`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Koncernfarven`,
				categoryIds: ['dominating']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Cyan eller lime`,
				categoryIds: ['text']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Sekundære kontrastfarver`,
				categoryIds: ['links']
			})
		]
	}),
	// case 2
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'design-multiple-choice-four-ps',
		text: `Design og kommunikations-afdelingen benytter sig af noget kaldet "de 4 P'er": Price (pris), Promotion (markedsføring), Place (distribution) og Product (produkt). Hvad udgør de fire P'er samlet for Nova Medical?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Virksomhedsfilosofi', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Virksomhedspræsentation`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Kerneværdier`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Reklametekst`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: `Markedsføringsstrategi`,
				isCorrect: true 
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		taskId: 'design-order-marked-strategy',
		layout: 'double-height',
		title: 'Opgave 2',
		text: `Når der skal laves en strategi for markedsføring, så arbejder man med tre forskellige faser. Sæt faserne i den rigtige rækkefølge. Tryk OK når I er klar.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'planning', 
				text: `Planlægningsfasen`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'implementing', 
				text: `Implementeringsfasen`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'evaluation', 
				text: `Evalueringsfasen`,
				correctOrderNumbers: [3]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'design-multiple-choice-choose-media',
		text: `I forlængelse af markedsføringsstrategien skal der udvælges to medier, hvor der skal reklameres for den nye insulin. Vælg de to bedste medier til at annoncere på.`,
		popupText: 'Da Nova hurtig sælger deres produkter til offentlige institutioner og virksomheder og ikke til private, er det bedst, at de viser deres produkter på hjemmesiden og ved at kontakte deres kunder direkte via telefon. Virksomheder er ikke på de sociale medier på samme måde, som private er.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'TikTok', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Instagram`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Facebook`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Youtube`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: `Hjemmeside`,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: `Aviser`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: `Streamingtjenester`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: `Telefonopkald`,
				isCorrect: true
			}),
		],
	}),
	// case 3
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		taskId: 'design-sort-combine-colors',
		title: 'Opgave 1',
		text: `Find en farve til indpakningen ved at blande sekundærfarver af de primærfarver, som I har på jeres lager.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'orange', 
				title: `Orange`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'green', 
				title: `Grøn`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'violet', 
				title: `Violet`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Rød og Gul`,
				categoryIds: ['orange']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Gul og blå`,
				categoryIds: ['green']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Rød og blå`,
				categoryIds: ['violet']
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 2A',
		taskId: 'design-multiple-choice-color-circle',
		text: `Når man arbejder med farver, kan man blandt andet bruge den tolvdelte farvekreds. Kig på figuren. Hvad kalder man farverne i den yderste ring?`,
		image: 'design-color-circle',
		options: [							
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Tertiære farver', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Sekundære farver`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Primære farver`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Koncernfarver`, 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 3,
		title: 'Opgave 2B',
		taskId: 'design-multiple-choice-complimentary',
		text: `Komplementærfarver er farver, som står i kontrast til hinanden. Kig nu på ringen i figuren. Hvad er røds komplementærfarve?`,
		image: 'design-color-circle',
		lockedById: 'design-multiple-choice-color-circle',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Grøn cirkel', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Gul cirkel`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Orange cirkel`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Turkis cirkel`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: `Blå cirkel`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: `Lilla cirkel`, 
			}),
		],
	}),
	// case 4
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'design-multiple-choice-rules',
		text: `Hvad er reglerne inden for sundhedsområdet i forhold til at reklamere for et lægemiddel til den offentlige forbruger? (Brug Google) `,
		popupText: `Lægemidlet fra Nova Medical er receptpligtigt, og derfor må virksomheden ikke reklamere direkte til offentligheden. De må dog gerne reklamere for produktet til medarbejdere i sundhedsvæsenet, fx læger, apoteker osv. I Danmark er det kun håndkøbsmedicin, der reklameres for direkte til offentlige forbrugere.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Nova Medical må betale læger for at rose produktet og anbefale det til deres patienter', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Nova Medical må ikke reklamere for deres lægemiddel direkte til forbrugeren, da det er receptpligtig medicin', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Nova Medical kan kontakte et reklamebureau og få dem til at reklamere for lægemidlet til offentlige forbrugere', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Nova Medical må betale patientforeninger for at lave en kampagne for lægemidlet direkte til offentlige forbrugere', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		taskId: 'design-order-marked-diabetes',
		layout: 'double-height',
		title: 'Opgave 2',
		text: `Sundhedsstyrelsen er ved at lancere en oplysningskampagne om diabetes. Diabetes kan forårsage farlige følgesygdomme. Diskuter hvilke konsekvenser af sygdommen, i tor der er de værste?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'blindness', 
				text: `Man er i risiko for at få et svækket syn, udvikle blindhed samt få nyresygdom.`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'footulcers', 
				text: `Man har en større risiko for fodsår og i sjældne tilfælde koldbrand.`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'evaluation', 
				text: `Man skal kontrollere sit blodsukker, blodtryk og kolesteroltal dagligt og tage den korrekte mængde medicin.`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'active', 
				text: `Man skal spise sundt og holde sig fysisk aktiv.`,
				correctOrderNumbers: [4]
			}),
		]
	}),
	// case 5
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1,
		title: 'Opgave 1A',
		taskId: 'design-multiple-choice-design-colors',
		text: `Kig på designguiden. Hvilke to farver tror I ledelsen har valgt til den nye hjemmeside?`,
		popupText: null,
		files: [
			{
				id: 'color-guide',
				type: 'image',
				btnText: 'Se designguide',
			}		
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Orange', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Blå'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Rosa',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Violet'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Gul',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Turkis ', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, text: 'Rød'
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 2,
		title: 'Opgave 1B',
		taskId: 'design-multiple-choice-url',
		lockedById: 'design-multiple-choice-design-font',
		text: `Vælg den URL der passer bedst til hjemmesiden.`,
		popupText: null,
		hintText: `Bindestreger er ikke gode i hjemmesider, og com er bedre til salg over landegrænsen.`,
		image: 'it-url',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'www&period;novamedical.dk'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'www&period;novamedical.com', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'www&period;novamedical.tv',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'www&period;nova-medical.dk'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'www&period;nova-medical.com'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'www&period;novamedical.nu'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, text: 'www&period;nova-medical.nu'
			}),


		],
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'htx-design-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};