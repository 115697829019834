import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import { facilitatorUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './popup-restart-game.scss';

const PopupRestartGame = ({ restartGame, handleGoToPage, closePopup }) => {
	/* Track if restarting game */
	const [isRestartingGame, setIsRestartingGame] = useState(false);

	/**
	 * Restart game
	 * @returns 
	 */
	const handleRestartGame = () => {
		if (isRestartingGame) return;
		setIsRestartingGame(true);
		restartGame().then(() => {
			setIsRestartingGame(false);
			handleGoToPage('game');
		}).catch((error) => {
			console.error(error);
			setIsRestartingGame(false);
		});

	};

	return (
		<div onClick={() => {closePopup();}} className="PopupRestartGame">
			<div onClick={(e) => {e.stopPropagation();}} className="PopupRestartGame-content">
				<div className='PopupRestartGame-title'><span>{facilitatorUiTexts.confirmRestartGame.title}</span></div>
				<div className='PopupRestartGame-text'>
					{renderMarkdown(facilitatorUiTexts.confirmRestartGame.text)}
				</div>
				<div className='PopupRestartGame-buttons'>
					<div className='PopupRestartGame-confirmBtn'>
						<Button 
							isLoading={isRestartingGame}
							classes={['login']}
							text={facilitatorUiTexts.confirmRestartGame.confirmBtn} 
							onClick={() => {handleRestartGame();}}
						/>
					</div>
					<div className='PopupRestartGame-cancelBtn'>
						<Button 
							classes={['login']}
							text={facilitatorUiTexts.confirmRestartGame.cancelBtn}
							onClick={() => {closePopup();}}
						/>
					</div>
				</div>
			</div> 
		</div>
	);
};

PopupRestartGame.propTypes = {
	restartGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default PopupRestartGame;
