const cookieUiTexts = {
	popupText: 'Vi bruger cookies til at forbedre din spiloplevelse. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
	popupBtn: 'Ok',
};

const errorUiTexts = {
	unknownError: 'Fejl: Ukendt fejl.',
	/* Create user (facilitator) */
	emptyFields: 'Du skal udfylde alle felter.',
	emailExists: 'Der findes allerede en bruger med denne email.',
	invalidPassword: 'Dit password skal være på mindst 8 tegn.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	userNotAllowed: 'Denne e-mail er ikke godkendt.',
	userAlreadyCreated: 'Denne e-mail er allerede i brug.',
	/* Login / reset password (facilitator) */
	emailNotFound: 'Der findes ingen bruger med denne email.',
	invalidEmail: 'Din email er ikke gyldig.',
	invalidEmailPasswordFormat: 'Fejl. Check om din email og password er korrekte.',
	/* Login (group) */
	noGameCodeMatch: 'Ugyldig spilkode.',
	/* Create group */
	invalidCharacters: 'Navnet indeholder et eller flere ugyldige tegn.',
	groupNameTooShort: 'Gruppenavn skal være på mindst 3 tegn.',
	groupNameTooLong: 'Gruppenavn må ikke være længere end 15 tegn.',
	noGroupName: 'Husk at udfylde et gruppenavn.',
	groupNameAlreadyExists: 'Der eksisterer allerede en gruppe med det navn.',

};

const loginUiTexts = {
	welcome: 'Velkommen til',
	loading: 'Loading',
	facilitatorLogin: 'LÆRER',
	adminLogin: 'Admin',
	superAdminLogin: 'Super admin',
	facilitatorResetPw: 'Nulstil password',
	facilitatorCreateUser: 'Opret ny bruger',
	groupLogin: 'ELEV',
	gameCode: 'Kodeord',
	groupLoginIntruction: 'Når din lærer opretter et spilforløb, genereres en unik spilkode.',
	login: 'Log ind',
	newGroup: 'Ny gruppe',
	writeGroupName: 'Skriv gruppenavn',
	groupName: 'Gruppenavn',
	create: 'Opret',
	cancel: 'Afbryd',
	email: 'E-mail',
	password: 'Password',
	repeatPassword: 'Gentag password',
	logout: 'Log ud',
	createNewUser: 'Opret ny bruger',
	create: 'Opret',
	newUserCreated: 'Din bruger er blevet oprettet, du kan nu logge ind.',
	backToLogin: 'Tilbage til login',
	resetPassword: 'Nulstil password',
	reset: 'Nulstil',
	passwordIsReset: 'Du har fået tilsendt en e-mail med et link til at nulstille dit password.',
	loggedInAs: 'Logget ind som',
	useGameCode: 'Indtast spilkoden:',
	loggingIn: 'Logger ind ...',
	loadingGame: 'Henter spil ...',
};

const adminUiTexts = {
	email: 'E-mail',
	status: 'Status',
	created: 'Oprettet',
	delete: 'Slet',
	addUser: 'Tilføj',
	backToGame: 'Tilbage',
	deleteUserWarning: {
		title: 'Slet bruger',
		text: 'Er du sikker på at du vil slette ',
		confirmDelete: 'Slet bruger',
		cancelDelete: 'Fortryd',
	},
};

const facilitatorUiTexts = {
	back: 'Tilbage',
	gameCode: 'Spilkode',
	startGameBtn: 'Start spil',
	closeGameBtn: 'Afslut spil',
	countdownTimer: 'Countdown timer',
	leaderboard: 'Leaderboard',
	rank: 'Rank',
	name: 'Navn',
	points: 'Point',
	landingPage: {
		welcomeTo: 'Velkommen til',
		title: 'Start spil',
		text: 'Vælg om du vil fortsætte igangværende spil eller starte et nyt.',
		restartGameBtn: 'Genstart spil',
		newGame: 'Start nyt spil',
		continueGame: 'Fortsæt spil',
		adminBtn: 'Admin'
	},
	confirmRestartGame: {
		title: 'Start nyt spil',
		text: 'Denne handling vil slette dit igangværende spil, vil du fortsætte?',
		cancelBtn: 'Fortryd',
		confirmBtn: 'Start nyt spil',
	},
	noGroupsWarning: {
		title: 'Start spil',
		text: 'Der er ikke nogen grupper logget ind, er du sikker på, du vil starte spillet?',
		cancelBtn: 'Fortryd',
		confirmBtn: 'Start spil'
	},
	closeGameWarning: {
		title: 'Afslut spil',
		text: 'Er du sikker på ...?',
		cancelBtn: 'Fortryd',
		confirmBtn: 'Afslut spil'
	},
	gameDonePage: {
		title: 'Final score',
	},

	gameReadyPage: {
		group: 'Grupper',
	},
};

const gameUiTexts = {
	resetBtn: 'Nulstil',
	backBtn: 'Tilbage',
	welcomeTo: 'Velkommen til',
	goToOffice: 'Gå ind på dit kontor',
	timesOut: 'tiden er gået',
	hint: 'Hint',
	choose: 'Vælg',
	ok: 'OK',
	points: 'Point',
	handOver: 'Aflever',
	correctAnswer: 'Korrekt svar',
	gameNotBegun: 'Spillet er ikke begyndt endnu',
	gameOver: 'Spillet er slut',
	nextAssignment: 'Næste opgave',
	continueBtn: 'Videre',
	exitAssignments: 'Tilbage til kontoret'
};

const generalUiTexts = {
}

export {
	cookieUiTexts,
	errorUiTexts,
	loginUiTexts,
	adminUiTexts,
	facilitatorUiTexts,
	gameUiTexts,
	generalUiTexts
};