import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { loginUiTexts, gameUiTexts } from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './landing-page-group.scss';

const LandingPageGroup = ({ game, gameId, resetGameProgress, handleGoToPage, handleLogout, updateBackground }) => {
	useEffect(() => {
		// Set background image id
		updateBackground('login', gameId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="LandingPageGroup">
			<div className="LandingPageGroup-welcome"><span>{gameUiTexts.welcomeTo}</span></div>
			<div className='LandingPageGroup-logoWrapper'>
				<Logo gameId={gameId} page="landing"/>
			</div>

			{/* Start game btn */}
			{game.status === 'open' ? 
				<div className='LandingPageGroup-startBtn'>
					<Button 
						classes={['blueLight', gameId, 'responsive']}
						text={gameUiTexts.goToOffice}
						onClick={() => {handleGoToPage('game');}}
					/>
				</div>
				:
				<div className='LandingPageGroup-gameNotStarted'>
					<span>{game.status === 'ready' ? gameUiTexts.gameNotBegun : gameUiTexts.gameOver}</span>
				</div>
			}

			{/* Logout button */}
			<div className="LandingPageGroup-logoutBtn">
				<Button 
					classes={['login']} 
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Reset game btn */}
			{(appConfig.env === 'development' || appConfig.env === 'test') && 
				<div className="LandingPageGroup-resetBtn">
					<Button 
						classes={['login']} 
						text={gameUiTexts.resetBtn}
						onClick={() => {resetGameProgress();}}
					/>
				</div>
			}
		</div>
	);
};

LandingPageGroup.propTypes = {
	game: PropTypes.object.isRequired,
	gameId: PropTypes.string.isRequired,
	resetGameProgress: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired
};

export default LandingPageGroup;
