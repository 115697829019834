import AreaHub from 'components/area-hub/area-hub';
import Area from 'components/area-hub/area';
import Case from 'components/area-hub/case';

const gamePagesData = {
	areaHub: {
		component: AreaHub,
		background: 'office',
		infoTitle: 'Velkommen',
		infoText: `Velkommen til Goodsellers. Som I ved sælger vi her fedt tøj til unge mennesker, og I har i dag fået til opgave, at hjælpe alle virksomhedens afdelinger. 
			<br /><br />Husk at vores 4 mål er: 
			<ul>
			<li>Gøre vores mærke kendt</li>
			<li>Tjene en masse penge til virksomheden</li>
			<li>Have glade og tilfredse kunder</li>
			<li>Drive forretning på en sund og effektiv måde</li>
			</ul>
			<p><br />Med dem i tankerne kan I score mange flere point.
			<br /><br />Jo bedre beslutninger I tager, jo flere point får I - rigtig god fornøjelse!</p>`,
		routes: [
			{
				id: 'sales',
				name: 'Salg',
				pageId: 'areaSales'
			},
			{
				id: 'marketing',
				name: 'Marketing',
				pageId: 'areaMarketing'
			},
			{
				id: 'it',
				name: 'IT',
				pageId: 'areaIt'
			},
			{
				id: 'management',
				name: 'Ledelse',
				pageId: 'areaManagement'
			},
			{
				id: 'economy',
				name: 'Økonomi',
				pageId: 'areaEconomy'
			}
		]
	},
	areaSales: {
		component: Area,
		caseComponent: Case,
		id: 'sales',
		name: 'Salg',
		background: 'sales',
		infoTitle: 'Salg',
		infoText: `Salgsafdelingen er på mange måder Goodsellers hjerte. Afdelingen står for planlægning af salg, drift af butikker, webshop og alt andet, der handler om at få virksomhedens lækre produkter ud til forbrugerne.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: `Priser`,
				pageId: null,
				text: `Hvad Goodsellers produkter skal koste og hvorfor er et af de helt centrale spørgsmål for virksomheden.`
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: `Fastsæt jeres priser`,
				pageId: null,
				text: `Når priserne på Goodsellers produkter skal fastsættes, sker dette ud fra at opnå en bestemt effekt på markedet i pågældende situation.`
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: `Kundeservice`,
				pageId: null,				
				text: `Kundeservice er altafgørende for en virksomhed som Goodsellers. Besøg butikken og se om I kan hjælpe der.`
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Online salg',
				pageId: null,
				text: `Goodsellers skal have en webshop. Men det er ikke noget, man bare lige gør. `,
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Distributionskæden',
				pageId: null,
				text: `Varens vej fra fabrik til kunde kaldes distributionskæden. Alle led i distributionskæden har en særlig rolle for Goodsellers. `,
			}
		]
	},
	areaMarketing: {
		component: Area,
		caseComponent: Case,
		id: 'marketing',
		name: 'Marketing',
		background: 'marketing',
		infoTitle: 'Marketing',
		infoText: `Marketing tager sig af reklamekampagner, annoncering og branding. Afdelingen beslutter hvem I samarbejder med og hvilke historier, I vil fortælle om og med jeres produkter. `,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Indretning',
				pageId: null,
				text: `I skal tage stilling til, hvordan I vil indrette jeres fysiske butik.`,
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Målgruppe',
				pageId: null,
				text: `Når man skal designe, markedsføre og sælge varer, skal man vide, hvilke kunder man går efter - altså hvem der er i ens målgruppe.`,
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Reklame',
				pageId: null,
				text: `I skal beslutte hvor og hvordan, I vil reklamere for Goodsellers produkter. `,
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Sociale medier',
				pageId: null,
				text: `Der er fokus på sociale medier, hvordan vil I markedsføre jer her?`
			}
		]
	},
	areaIt: {
		component: Area,
		caseComponent: Case,
		id: 'it',
		name: 'IT',
		background: 'it',
		infoTitle: 'IT',
		infoText: `IT tager sig af alt det digitale. Det handler både om noget så lavpraktisk som jeres computere, men også om alt det, der får jeres webshop til at fungere.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Oprydning',
				pageId: null,
				text: `Der er brug for at få ryddet op i IT. `,
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Nye computere',
				pageId: null,
				text: `I har en del computere i virksomheden. De skal med jævne mellemrum udskiftes og vedligeholdes.`
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Datasikkerhed',
				pageId: null,
				text: `I Goodsellers opbevarer vi følsomme persondata. Ledelsen skal derfor løbende vurdere, om sikkerheden er god nok. `,
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Robotter',
				pageId: null,
				text: `Goodsellers ønsker at benytte robotteknologi i fremtiden. Ledelsen skal dog have forståelse for den nye måde at tænke produktionsprocessen på.`
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Website',
				pageId: null,
				text: `I skal vælge designet til Goodsellers hjemmeside.`
			}
		]
	},
	areaManagement: {
		component: Area,
		caseComponent: Case,
		id: 'management',
		name: 'Ledelse',
		background: 'management',
		infoTitle: 'Ledelse',
		
		infoText: `Her handler det om den daglige drift og alle de små problemer, der kan opstå. Men ledelse handler også om visioner og strategi. Hvilken retning skal virksomheden gå? Hvad er jeres mål? Og hvordan vil I få pengene til at nå derhen?`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Mål for virksomheden',
				pageId: null,
				text: `Goodsellers er en nystartet virksomhed. I vil gerne have den til at vokse, og det kræver nogle klare og realistiske mål. `,
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Varemærker',
				pageId: null,
				text: `Det er helt normalt for tøjvirksomheder at efterligne hinanden og på den måde være sammen om at skabe mode og trends. Men, det kan være risikabelt at efterligne andre for meget, da man krænker deres rettigheder til deres design. 
				<br /><br />En af vores designere har ladet sig inspirere meget af det engelske tøjmærke Superdry. Spørgsmålet er om det er for meget. `,
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Uforudsete hændelser',
				pageId: null,
				text: `Som ledere af virksomheden skal I reagere, hvis der sker noget uforudset. Derfor er det altid godt at være up to date med dagens nyheder. `,
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Medarbejdere',
				pageId: null,
				text: `Kommunikation handler i høj grad om det interne - altså hvordan man kommunikerer med medarbejderne. `
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Ledelsesviden',
				pageId: null,
				text: `Ledelsen har brug for information om virksomheden. Hjælp dem med at få styr på tingene. `
			}
		]
	},
	areaEconomy: {
		component: Area,
		caseComponent: Case,
		id: 'economy',
		name: 'Økonomi',
		background: 'economy',
		infoTitle: 'Økonomi',
		infoText: `I økonomi kan I prøve kræfter med virksomhedens regnskaber, indkøb og andre spændende beregninger.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'SKAT',
				pageId: null,
				text: `Hvad oplyser man egentlig om en virksomhed?`
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Vigtige tal',
				pageId: null,
				text: `I en økonomiafdeling regner man på mange ting. Hjælp Goodsellers økonomiafdeling.`
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Indkøb',
				pageId: null,
				text: `Indkøb er en central del af Goodsellers forretning. Det gælder om at finde de rigtige varer til den rigtige pris.`
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Indkøbsmedarbejder',
				pageId: null,
				text: `At være indkøber er en speciel titel, og det er ikke fordi man bare køber alt ind. Hvem tror I en indkøber er?`
			}
		]
	},
};

export {
	gamePagesData,
};