import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {loginUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import LandingPageFacilitator from 'components/landing-page-facilitator/landing-page-facilitator';
import Admin from 'components/admin/admin';
import GameReady from 'components/game-ready/game-ready';
import GameOpen from 'components/game-open/game-open';
import GameDone from 'components/game-done/game-done';


class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'landing', // landing, admin, game
			userData: null,
			game: null,
			groups: []
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeGame = null;
		this.unsubscribeGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get facilitator data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				/* Subscribe to game */
				this.subscribeToGame().then(() => {
					if (!this.state.game) {
						/* No game? -> create game */
						this.createGame().then(() => {
							this.setState({isLoading: false});
						}).catch((error) => {
							console.error(error);
						});
					} else {
						this.setState({isLoading: false});
					}
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe from game & groups */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeUser !== null) this.unsubscribeUser();
		
	};

	/**
	 * Subscribe to user (facilitator) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection(appConfig.usersDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {resolve({ status: 'success' });});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			});
		});
	};

	/**
	 * Called by the facilitator to subscribe to their game
	 */
	subscribeToGame = () => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName).where('facilitatorId', '==', this.props.userId);			
			this.unsubscribeGame = query.onSnapshot((querySnapshot) => {
				let game = null;
				querySnapshot.forEach((doc) => {
					if (!game && doc.data().gameId === this.props.gameId) game = {id: doc.id, ...doc.data()};
				});

				const startNewGroupSubscription = (game && (!this.state.game || this.state.game.id !== game.id) 
					? true : false);
				this.setState({game: game}, () => {
					if (startNewGroupSubscription) this.subscribeToGroups();
					resolve({status: 'success'});
				});
			}, (error) => {
				console.error('could not get game: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to the groups of a game
	 * @param {string} gameId
	 */
	subscribeToGroups = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		
		const gameId = this.state.game.id;
		return new Promise((resolve, reject) => {
			let db = firebase.firestore();
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({ groups: groups }, () => {
						resolve();// resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Create game
	 */
	createGame = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/create-game', {
				facilitatorId: this.props.userId,
				gameId: this.props.gameId
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Reset game (develop mode)
	 */
	restartGame = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/restart-game', {
				facilitatorId: this.props.userId,
				gameId: this.props.gameId
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Update user (facilitator) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection('users').doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.game.id;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};

	/**
	 * Go to page (game / admin)
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		this.setState({page});
	};


	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading || !this.state.game) {
			return (
				<Loading 
					loadErrMsg={loginUiTexts.loadingGame}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};
		/* Admin */
		if (this.state.page === 'admin') {
			return (
				<Admin 
					gameId={this.props.gameId}
					handleGoToPage={this.handleGoToPage}
					updateBackground={this.props.updateBackground}
				/>
			);
		}
		/* Game */
		if (this.state.page === 'game') {
			/* Game status: done */
			if (this.state.game.status === 'done') {
				return (
					<GameDone
						gameId={this.props.gameId}
						groups={this.state.groups}
						game={this.state.game}
						handleGoToPage={this.handleGoToPage}
						updateBackground={this.props.updateBackground}
						handlePlayMusic={this.props.handlePlayMusic}
					/>
				);
			}
			/* Game status: open */
			if (this.state.game.status === 'open') {
				return (
					<GameOpen 
						gameId={this.props.gameId}
						groups={this.state.groups}
						game={this.state.game}
						updateGame={this.updateGame}
						handleGoToPage={this.handleGoToPage}
						updateBackground={this.props.updateBackground}
						handlePlayMusic={this.props.handlePlayMusic}
					/>
				);
			}
			/* Game status: ready */
			return (
				<GameReady 
					game={this.state.game}
					groups={this.state.groups}
					updateGame={this.updateGame}
					handleGoToPage={this.handleGoToPage}
					updateBackground={this.props.updateBackground}
					handlePlayMusic={this.props.handlePlayMusic}
				/>
			);
		}


		/* Landing page */
		return (
			<LandingPageFacilitator 
				gameId={this.props.gameId}
				role={this.state.userData.role}
				restartGame={this.restartGame}
				handleLogout={this.props.handleLogout}
				handleGoToPage={this.handleGoToPage}
				updateBackground={this.props.updateBackground}
			/>
		);

	}
}

FacilitatorController.propTypes = {
	gameId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default FacilitatorController;
