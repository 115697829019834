import React from 'react';
import { Link } from 'react-router-dom';
import { gamesData } from 'data/games-data';
import './select-game.scss';

const SelectGame = () => {
	return (
		<div className="SelectGame">
			<div className="SelectGame-games">
				{Object.keys(gamesData).map((key)=>{
					return (
						<Link key={key} to={gamesData[key].url} className={'SelectGame-game ' + key} />
					);
				})}
				
			</div>
		</div>
	);
};


export default SelectGame;