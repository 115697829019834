import React from 'react';
import './background.scss';
import PropTypes from 'prop-types';
/* Game 1 backgrounds */
import { ReactComponent as Office } from '../../../assets/images/backgrounds/office.svg';
import { ReactComponent as Research } from '../../../assets/images/backgrounds/research.svg';
import { ReactComponent as It } from '../../../assets/images/backgrounds/it.svg';
import { ReactComponent as Prep } from '../../../assets/images/backgrounds/prep.svg';
import { ReactComponent as Design } from '../../../assets/images/backgrounds/design.svg';
import { ReactComponent as Production } from '../../../assets/images/backgrounds/production.svg';
import { ReactComponent as Login1 } from '../../../assets/images/backgrounds/login-game1.svg';
import { ReactComponent as Facilitator } from '../../../assets/images/backgrounds/facilitator.svg';

/* Game 2 backgrounds */
import { ReactComponent as Login2 } from '../../../assets/images/backgrounds/login-game2.svg';
import { ReactComponent as Office2 } from '../../../assets/images/backgrounds/office2.svg';
import { ReactComponent as Marketing } from '../../../assets/images/backgrounds/marketing.svg';
import { ReactComponent as Management } from '../../../assets/images/backgrounds/management.svg';
import { ReactComponent as Sales } from '../../../assets/images/backgrounds/sales.svg';
import { ReactComponent as It2 } from '../../../assets/images/backgrounds/it2.svg';
import { ReactComponent as Economy } from '../../../assets/images/backgrounds/economy.svg';
import { ReactComponent as Facilitator2 } from '../../../assets/images/backgrounds/facilitator2.svg';

const Background = ({ background }) => {
	let BackgroundImage = null;

	if (background && background.gameId === 'game1') {
		/* Game 1 backgrounds */
		switch (background.backgroundId) {
		case 'office':
			BackgroundImage = Office;
			break;
		case 'research':
			BackgroundImage = Research;
			break;
		case 'it':
			BackgroundImage = It;
			break;
		case 'build':
			BackgroundImage = Office;
			break;
		case 'prep':
			BackgroundImage = Prep;
			break;
		case 'design':
			BackgroundImage = Design;
			break;
		case 'production':
			BackgroundImage = Production;
			break;
		case 'login':
			BackgroundImage = Login1;
			break;
		case 'facilitator':
			BackgroundImage = Facilitator;
			break;		
		default:
			break;
		}
	} else if (background && background.gameId === 'game2') {
		/* Game 2 backgrounds */
		switch (background.backgroundId) {
		case 'office':
			BackgroundImage = Office2;
			break;
		case 'marketing':
			BackgroundImage = Marketing;
			break;
		case 'it':
			BackgroundImage = It2;
			break;
		case 'management':
			BackgroundImage = Management;
			break;
		case 'sales':
			BackgroundImage = Sales;
			break;
		case 'login':
			BackgroundImage = Login2;
			break;
		case 'facilitator':
			BackgroundImage = Facilitator2;
			break;
		case 'economy':
			BackgroundImage = Economy;
			break;
		default:
			break;
		}
	}
	
	return (
		<div className='Background'>
			{BackgroundImage && 
				<BackgroundImage className={'Background-image Background-' + background.backgroundId}/>
			}
		</div>
	);
};

Background.propTypes = {
	background: PropTypes.object.isRequired,
};

export default Background;