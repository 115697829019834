const taskTemplates = require('../../task-templates');

const tasksData = [
	/* case 1 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'sales-multiple-choice-prices',
		text: `I skal fastsætte jeres priser, så I tjener flest mulig penge til Goodsellers. 
			<br />Hvad er vigtigt, når I bestemmer priserne på varerne?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Konkurrentens priser', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Virksomhedens udgifter',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Kundernes rådighedsbeløb',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Jeres private udgifter',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2A',
		taskId: 'sales-multiple-choice-hoodie-price',
		text: `I skal finde en pris til jeres populære hoodie ”Street”. Det er en fed hoodie med ægte streetart grafitti og kunstnersignaturer på.
			<br />  Hvordan vil I fastsætte prisen?`,
		hintText: `Når man fastsætter priser er det en god ide, at tænke hvor eftertragtede de er. Jo mere eftertragtet en vare, jo mere kan man tage for den.`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Start høj og så gradvis lavere', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Start lav og så gradvist op'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Start lav og sæt meget op hvis der er efterspørgsel', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2.3,
		lockedById: 'sales-multiple-choice-hoodie-price',
		title: 'Opgave 2B',
		taskId: 'sales-multiple-choice-tshirt-price',
		text: `Jeres t-shirt ”Flashy” er en standard sort t-shirt uden logo. 
			<br /> Hvordan skal prisen være på den? `,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Start høj og så gradvis lavere', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Start lav og så gradvist op',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2.6,
		lockedById: 'sales-multiple-choice-tshirt-price',
		taskId: 'sales-sort-skimming-penetration-prices',
		title: 'Opgave 2C',
		text: `Når man sætter prisen højt og så gradvis sætter den ned, så kaldes det "skimming price", fordi man 'skummer' markedet. Sættes prisen lavt, kaldes det ”penetration price”. Her er fokus på at trænge ind på et marked, hvorefter prisen kan sættes op.
		<br /><br /> Snak sammen og sorter sætningerne alt efter om de passer til skimming eller penetration price.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'skimming', 
				title: `Skimming price`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'penetration', 
				title: `Penetration price`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Passer til markeder med mange konkurrenter`,
				categoryIds: ['penetration']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Passer til kunder der vil have det nyeste nu`,
				categoryIds: ['skimming']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Giver høj fortjeneste på den korte bane`,
				categoryIds: ['skimming']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Kan skræmme folk væk når prisen stiger`,
				categoryIds: ['penetration']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Signalerer høj kvalitet`,
				categoryIds: ['skimming']
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'sales-multiple-choice-sales-tax',
		text: `Når I skriver priser, skal de så være inklusiv eller ekskluvis moms?`,
		popupText: `I sælger til forbrugere og ikke til virksomheder. Derfor skal jeres priser altid være angivet inklusiv 25% moms.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Altid inklusiv', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Altid eksklusiv',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Inklusiv i butikken, eksklusiv på web',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Inklusiv på web, eksklusiv i butikken',
			}),
		],
	}),
	/* case 3 */
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'sales-paper-doll-equipment',
		layout: 'sales-equipment',
		avatarId: 'avatar-2',
		text: `Hvad skal de ansatte huske, når de går på arbejde i butikken?
		</br> Vælg, hvad I tror er det rigtige og tryk så OK. `,
		items: [
			
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'shirt-stained', title: 'Plettet skjorte'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'shirt', title: 'Goodsellers trøje', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'name-tag2', title: 'Navneskilt', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'phone', title: 'Mobiltelefon'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'pen2', title: 'Kuglepen', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'smile', title: 'Stort smil', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'whistle', title: 'Fløjte'
			}),

		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'sales-multiple-choice-first-impressions',
		text: `En kunde er netop trådt ind i jeres forretning. Hvilken af følgende ting er IKKE vigtig for et godt førstehåndsindtryk?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'De første 20 sekunder af samtalen', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'De første skridt hen mod kunden'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At vise fem varer du tror, kunden mangler',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'De første 20 ord du siger'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hvor tæt du stiller dig på kunden'
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'sales-spot-errors-store',
		layout: 'sales-store',
		text: `En pæn butik lokker kunder til. <br/>
		Se om I kan spotte de ting, som gør butikken mindre indbydende for kunderne. Tryk OK når I tror, I har dem alle. `,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'paper-on-floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'broken-lamp'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'box-with-knife'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'clothes'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sales-man'}),
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 4,
		taskId: 'sales-sort-shop-window',
		layout: 'sales-shop-window',
		title: 'Opgave 4',
		text: `Et af butikkens udstillingsvinduer skal se lækkert ud. Placer produkterne de rigtige steder. `,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'top-shelf', 
				type: 'image'
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'middle-shelf', 
				type: 'image'
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'bottom-shelf', 
				type: 'image'
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				type: 'image',
				categoryIds: ['top-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				type: 'image',
				categoryIds: ['top-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				type: 'image',
				categoryIds: ['top-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				type: 'image',
				categoryIds: ['middle-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				type: 'image',
				categoryIds: ['middle-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				type: 'image',
				categoryIds: ['middle-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, 
				type: 'image',
				categoryIds: ['bottom-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 8, 
				type: 'image',
				categoryIds: ['bottom-shelf']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 9, 
				type: 'image',
				categoryIds: ['bottom-shelf']
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 5,
		taskId: 'sales-sort-typical-day',
		title: 'Opgave 5',
		text: `Hvad skal du gøre hvornår på en typisk dag som sælger i en butik? Træk handlingerne hen i de rigtige kasser. `,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'before', 
				title: `Før åbning`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'during', 
				title: `I løbet af dagen`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'closing', 
				title: `Efter lukketid`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Gøre butikken pæn`,
				categoryIds: ['closing']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Tjekke dagens omsætning`,
				categoryIds: ['closing']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Sæt varer på plads`,
				categoryIds: ['during']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Opfyldning af nye varer`,
				categoryIds: ['before']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Tale med kunder`,
				categoryIds: ['during']
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 6,
		title: 'Opgave 6',
		taskId: 'sales-multiple-choice-hoodies',
		text: `Du har travlt med at pakke varer ud. En kunde spørger, hvor han kan finde hoodies. Hvad gør du?`,
		popupText: `Sørg altid for at give kunderne bedst mulig service. Så øger du chancen for de vender tilbage.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Stop med at pakke ud og peg kunden i den rigtige retning', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Henvis kunden til en kollega som ikke har travlt'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Følg kunden ned til det rigtige sted',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 7,
		title: 'Opgave 7',
		taskId: 'sales-spot-errors-receiving-products',
		layout: 'sales-receiving-products',
		text: `Se om du kan spotte alle fejlene her på lageret. Tryk OK, når I tror, I har dem alle. `,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'boxes-on-pallet'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'driving-one-handed'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'messy-boxes'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'heavy-lifting'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'liquid-on-floor'}),
		]
	}),
	/* case 4 */
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'sales-input-answer-break-even',
		text: `En webshop kræver en stor investering, men I tror også, at det vil kunne fordoble overskuddet hvert år. Hvis investeringen er 1.500.000 kr. og der i år er et overskud på 100.000 kr., hvor mange år vil det så tage, før investeringen er tjent hjem? `,
		answer: 4,
		unit: 'år.',
		image: null
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		taskId: 'sales-order-webpage',
		title: 'Opgave 2',
		text: `Hvad kræver det for at lave en succesfuld webshop?
		<br />Sæt følgende i rækkefølge fra mest til mindst vigtigt for salg i en webshop.`,
		text: 'For at lave en succesfuld online hjemmeside som sælger er der mange vigtige ting at tænke på, sorter i rækkefølge hvad I mener er mest vigtigt for salget.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'ui', 
				text: `Hjemmesiden skal være nem at navigere rundt på`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'products', 
				text: `Hjemmesiden skal have billeder at produkterne`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'allproducts', 
				text: `Vi har alle vores produkter på hjemmesiden`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'url', 
				text: `En nem URL adresse til hjemmesiden`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'picture', 
				text: `Lyd og levende billeder på hjemmesiden`,
				correctOrderNumbers: [5]
			})
		]
	}),
	/* case 5 */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1,
		taskId: 'sales-order-supply-chaing',
		title: 'Opgave 1',
		text: `Tal sammen i teamet om, hvordan en distributionskæde er opbygget. Sæt så leddene i den rigtige rækkefølge. `,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'producent', 
				text: `Whatz Clothing Singapore (producent)`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'distributor', 
				text: `Storkøb A/S (distributør)`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'shops', 
				text: `GoodSellers (10 butikker)`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'consumers', 
				text: `Forbrugerne`,
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 2,
		title: 'Opgave 2A',
		taskId: 'sales-multiple-choice-delivery-offers',
		text: `I har fået tre tilbud på leverancer af hoodies og T-shirts. Alle tilbud er leverance á 1000 stk. T-shirts og 500 stk. hoodies. 
			<br />Prisen er indkøbspris pr. stk.:
			<br /><br />A produktion:
			<br />T-shirts: 60 kr. // hoodies: 120 kr.
			<br /><br />B-fashion:
			<br />T-shirts: 70 kr. // hoodies: 100 kr.
			<br /><br />Cirko tøj:
			<br />T-shirts 45 kr. // hoodies: 130 kr.
			<br /><br />I kan sælge jeres t-shirts for 140 kr. og jeres hoodies for 260 kr. stykket og I vil gerne tjene så meget som muligt. Hvilket firma skal I vælge, hvis I kun kigger på pris?
		`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'A produktion', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'B-fashion',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Cirko Biz',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 2.5,
		title: 'Opgave 2B',
		lockedById: 'sales-multiple-choice-delivery-offers',
		taskId: 'sales-input-answer-delivery-offers',
		text: `I vælger B-fashion. I skal have 1000 t-shirts og 500 hoodies. Prisen per styk er: 
			<br />T-shirts 70
			<br />Hoodies: 100
			<br /><br />Hvad bliver den samlede pris?`,
		answer: 120000,
		unit: 'kr',
		image: null
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'hhxedu-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};