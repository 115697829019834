import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { errorUiTexts } from 'data/ui-texts';
import LoginFacilitator from './login-facilitator';

class LoginFacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			feedback: null,
			isLoading: false,
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();
		this.setState({isLoading: true});
		let email = this.state.email;
		let password = this.state.password;
		
		let feedback = null;
		if (!email || !password) feedback = errorUiTexts.emptyFields;
		if (!feedback) {
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					if (error.code === 'auth/user-not-found') feedback = errorUiTexts.emailNotFound;
					if (error.code === 'auth/wrong-password') feedback = errorUiTexts.invalidEmailPasswordFormat;
					if (error.code === 'auth/invalid-email') feedback = errorUiTexts.invalidEmail;
					if (!feedback) {
						console.error(error);
						feedback = errorUiTexts.unknownError;
					}
					this.setState({isLoading: false, feedback});
				});
		} else {
			this.setState({isLoading: false, feedback});
		}
	};


	render = () => {
		return (
			<LoginFacilitator
				isLoading={this.state.isLoading}
				email={this.state.email}
				password={this.state.password}
				feedback={this.state.feedback}
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
				gameId={this.props.gameId}
			/>
		);
	};
}

LoginFacilitatorController.propTypes = {
	setFacilitatorBoxType: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default LoginFacilitatorController;