import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shuffleArray } from 'helpers/array-helper';
import { renderMarkdown } from 'helpers/text-helper';
import TaskIntro from 'components/game-engines/task-intro/task-intro';
import './multiple-choice.scss';

const MultipleChoice = (props) => {
	const {
		isCompleted, 
		playerTaskData, 
		taskData, 
		setPopupFileData, 
		handleUpdateTask, 
		handleCompleteTask,
		gameId
	} = props;

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false); 
	
	/* Get number of correct answers */
	const numberOfCorrectAnswers = (taskData.isDilemma
		? taskData.numberOfAnswersToSelect
		: taskData.options ? taskData.options.filter((o) => {return o.isCorrect === true;}).length : 0
	);

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);

	/* Multiline */
	const isMultiline = (taskData.multiline === 'auto'
		? (optionIds.length > 5 || taskData.image ? true : false)
		: (taskData.multiline === 'on' ? true : false)
	);

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSelectedOptionIds = () => {
		let optionIds = [];
		if (playerTaskData && playerTaskData.selectedOptionIds) optionIds = playerTaskData.selectedOptionIds;
		return optionIds;
	};


	
	/* Update selected items if new task */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setSelectedOptionIds(getSelectedOptionIds());
		setAnimateSelectedOptions(false);

		const optionsElement = document.getElementById('multipleChoiceOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData]);


	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Already completed */
		if (isCompleted === true) return;
		
		/* Already selected */
		const optionIndex = selectedOptionIds.indexOf(optionId);
		if (optionIndex >= 0) return;
	
		/* Enable animation */
		setAnimateSelectedOptions(true);

		/* Select option */
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		newSelectedOptionIds.push(optionId);
		setSelectedOptionIds(newSelectedOptionIds);
		
		/* Check if task is completed */
		const taskIsCompleted = (taskData.isDilemma
			? taskData.numberOfAnswersToSelect === newSelectedOptionIds.length
			: checkIfAllCorrectOptionsAreSelected(newSelectedOptionIds)
		);

		if (taskIsCompleted) {
			/* Complete task */
			completeTask(newSelectedOptionIds);
		} else {
		/* Get number of wrong answers */
			let wrongAnswers = 0;
			if (!taskData.isDilemma) {
				newSelectedOptionIds.forEach((selectedOptionId) => {
					if (!taskData.options.some((optionData) => {
						return (optionData.id === selectedOptionId && optionData.isCorrect === true);
					})) {
						wrongAnswers += 1;
					}
				});
			}

			handleUpdateTask(
				'multiple-choice' + (taskData.isDilemma ? '-dilemma' : ''),
				{selectedOptionIds: newSelectedOptionIds, errors: wrongAnswers}
			);
		}
	};

	/**
	 * Check if all correct options are selected (i.e. if task is complete)
	 * @param {array} newSelectedOptionIds 
	 * @returns 
	 */
	const checkIfAllCorrectOptionsAreSelected = (newSelectedOptionIds) => {
		let allCorrectOptionsAreSelected = true;
		taskData.options.forEach((optionData) => {
			if (optionData.isCorrect === true && newSelectedOptionIds.indexOf(optionData.id) < 0) {
				allCorrectOptionsAreSelected = false;
			}
		});
		return allCorrectOptionsAreSelected;
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 * @param {number} newPoints
	 */
	const completeTask = (newSelectedOptionIds, newPoints) => {
		
		/* Get number of wrong answers */
		let wrongAnswers = 0;
		if (!taskData.isDilemma) {
			newSelectedOptionIds.forEach((selectedOptionId) => {
				if (!taskData.options.some((optionData) => {
					return (optionData.id === selectedOptionId && optionData.isCorrect === true);
				})) {
					wrongAnswers += 1;
				}
			});
		}

		/* Check of special points */
		let specialPoints = 0;
		newSelectedOptionIds.forEach((optionId) => {
			const selectedOptionData = (taskData.options 
				? taskData.options.find((o) => {return o.id === optionId;})
				: 0
			);
			if (selectedOptionData.points) specialPoints += selectedOptionData.points;
		});
		
		/* Save completed task */
		handleCompleteTask(
			'multiple-choice' + (taskData.isDilemma ? '-dilemma' : ''),
			{selectedOptionIds: newSelectedOptionIds, errors: wrongAnswers, specialPoints: specialPoints}
		);
	};

	let taskIntroImage = taskData.image;
	if (isCompleted && taskData.imageSolved) taskIntroImage = taskData.imageSolved;

	return (
		<div className={'MultipleChoice '
			+ (taskData.isDilemma ? ' dilemma' : '')
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
		}
		>
			<div className="MultipleChoice-intro">
				<TaskIntro 
					showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
					numberOfCorrectAnswers={numberOfCorrectAnswers} 
					text={taskData.text}
					image={taskIntroImage}
					files={taskData.files}
					setPopupFileData={setPopupFileData}
					gameId={gameId}
				/>
			</div>
			<div className={'MultipleChoice-options' + (isMultiline ? ' multiline' : '')}>				
				{optionIds.map((optionId, index) => {
					const optionData = taskData.options.find((option) => {return option.id === optionId;});
					if (!optionData) return null;
					const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;
					let optionClass = 'MultipleChoice-option ' + gameId;
					if (isSelected) optionClass += ' selected';
					if (!isSelected && isCompleted) optionClass += ' completed';
					if (isSelected && !taskData.isDilemma && optionData.hasOwnProperty('isCorrect')) {
						if (optionData.isCorrect) {
							optionClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
						} else {
							optionClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
						}
					}						
					if (taskData.layout) optionClass += ' option-' + optionData.id + ' position-' + (index + 1);

					if (taskData.isDilemma && isSelected) {
						let task = taskData.options.filter((option) => {return option.id === optionId; })[0];
						if (task.points > 0) {
							optionClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
						} else {
							optionClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
						}
					}
					return (
						<div 
							key={optionData.id} 
							className={optionClass} 
							onClick={() => {selectOptionId(optionData.id);}}
						>
							<span>{renderMarkdown(optionData.text)}</span>
							<span></span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

MultipleChoice.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	setPopupFileData: PropTypes.func.isRequired,
	handleUpdateTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default MultipleChoice;
