import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({gameId, page}) => {
	return (
		<div className={'Logo' + (gameId ? ' ' + gameId : '') + (page ? ' ' + page : '')}/>
	);
};


Logo.propTypes = {
	gameId: PropTypes.string,
	page: PropTypes.string,
};

export default Logo;
