const taskTemplates = require('../../task-templates');

const tasksData = [
	/* case 1 */
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'it-spot-errors-does-not-belong',
		layout: 'it-does-not-belong',
		text: `Marker de 5 ting som ikke hører hjemme i IT afdelingen.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wire'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'safety-glasses'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'drone'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'lamp'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'ash-tray'}),
		]
	}),
	/* case 2 */
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'it-input-answer-computer-budget',
		text: `Computerne skal udskiftes om fem år, og det kommer til at koste kr. 320.000. Hvilket årligt beløb skal der afsættes i budgettet, for at pengene er der, når I skal bruge dem?`,
		answer: 64000,
		unit: 'kr.',
		image: null
	}),
	/* case 3 */
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		taskId: 'it-sort-personal-data-risk',
		title: 'Opgave 1',
		text: `Sorter følgende alt efter om der i forhold til persondata er en acceptabel risiko eller en kritisk risiko. `,
		layout: 'double-height',
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'acceptable', 
				title: `Acceptabel risiko`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'critical', 
				title: `Kritisk risiko `
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Medarbejdernes CPR numre og kontonumre registreres i et sikkert IT system`,
				categoryIds: ['acceptable']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Vi har en liste med mail og telefon på vores kunder liggende i butikken`,
				categoryIds: ['critical']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Vi har kontrakter liggende i vores bankboks`,
				categoryIds: ['acceptable']
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'it-multiple-choice-data-role',
		text: `Når Goodsellers opsamler data om kunder, hvad er så jeres rolle i forhold til den data?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Vi er dataansvarlige', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Vi er registrerede'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Vi er rettighedshaver',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Vi er en cookie'
			})
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'it-input-answer-pin-codes',
		text: `I har fire-cifrede pinkoder på jeres computere. Hvor mange forskellige mulige koder giver det?`,
		answer: 10000,
		unit: 'forskellige koder',
		hintText: `Man kan bruge 0-9 på alle fire pladser. Gang antal muligheder på hver plads med hinanden for at finde svaret.`
	}),
	/* case 4 */	
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		taskId: 'it-order-robot-tea',
		title: 'Opgave 1',
		text: 'Forestil dig, at du skal få en robot til at lave en kop te. Husk: En robot udfører nøjagtigt det du beder den om, hverken mere eller mindre. Sæt kommandoerne i den korrekte rækkefølge.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'lift-kettle', 
				text: `Løft elkoger`,
				correctOrderNumbers: [1, 9]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'move', 
				text: `Flyt elkoger hen under vandhanen`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'turn-on-water', 
				text: `Tænd vandet`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'turn-off-water', 
				text: `Sluk vandet når elkoger er fyldt`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'move-kettle-to-correct-place', 
				text: `Sæt elkoger på plads`,
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'turn-on-kettle', 
				text: `Tænd elkogeren`,
				correctOrderNumbers: [6]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'take-the', 
				text: `Tag fat i te-brev`,
				correctOrderNumbers: [7]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'place-the-into-kop', 
				text: `Put te-brev i kop`,
				correctOrderNumbers: [8]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'lift-kettle-again', 
				text: `Løft elkoger`,
				correctOrderNumbers: [9, 1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'pour-the-water-on-top', 
				text: `Hæld vand fra elkoger i kop`,
				correctOrderNumbers: [10]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'it-multiple-choice-robot-typical-jobs',
		text: `Robotter er kun gode til nogle typer af opgaver. Hvilke tre opgaver har robotter typisk på en fabrik?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Montage', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Skæring',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Opsamling og pakning',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Inspektion'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Syning',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Varierede opgaver'
			})
		],
	}),
	/* case 5 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1,
		title: 'Opgave 1A',
		taskId: 'it-multiple-choice-design-colors',
		text: `Kig på designguiden. Hvilke to farver tror I ledelsen har valgt til den nye hjemmeside?`,
		popupText: null,
		files: [
			{
				id: 'color-guide',
				type: 'image',
				btnText: 'Se designguide',
			}		
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Orange', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Blå'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Rosa',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Violet'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Gul',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Turkis ', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, text: 'Rød'
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1.3,
		subtype: 'images',
		title: 'Opgave 1B',
		taskId: 'it-multiple-choice-design-font',
		lockedById: 'it-multiple-choice-design-colors',
		text: `Hvilken font er den mest brugte hjemmeside font?`,
		layout: 'it-fonts',
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'arial', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'roboto'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'times',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'calibri'
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1.9,
		title: 'Opgave 1C',
		taskId: 'it-multiple-choice-url',
		lockedById: 'it-multiple-choice-design-font',
		text: `Vælg den URL der passer bedst til hjemmesiden.`,
		popupText: null,
		hintText: `Bindestreger er ikke gode i hjemmesider, og com er bedre til salg over landegrænsen.`,
		image: 'it-url',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'www&period;goodsellers.dk'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'www&period;goodsellers.com', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'www&period;goodsellers.tv',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'www&period;good-sellers.dk'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'www&period;good-sellers.com'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'www&period;goodsellers.nu'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, text: 'www&period;good-sellers.nu'
			}),


		],
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'hhxedu-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};