import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import { adminUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin.scss';
import PopupDeleteUser from 'components/ui/popup-delete-user/popup-delete-user';
import apiHelper from 'helpers/api-helper';

const Admin = ({ handleGoToPage, updateBackground, gameId }) => {
	/* Users & allowed users (from database) */
	const [users, setUsers] = useState([]);
	const [allowedUsers, setAllowedUsers] = useState([]);
	const [popupData, setPopupData] = useState(null);

	useEffect(() => {
		/* Component did mount, subscribe to users and allowed users */
		const db = firebase.firestore();
		const unsubscribeUsers = db.collection(appConfig.usersDbName).onSnapshot(
			(querySnapshot) => {
				let dbUsers = [];
				querySnapshot.forEach((doc) => {dbUsers.push({id: doc.id, ...doc.data()});});
				setUsers(dbUsers);
			},
			(error) => {
				console.error('could not get users: ', error);
			}
		);
		const unsubscribeAllowedUsers = db.collection(appConfig.allowedUsersDbName).onSnapshot(
			(querySnapshot) => {
				let dbAllowedUsers = [];
				querySnapshot.forEach((doc) => {dbAllowedUsers.push({id: doc.id, ...doc.data()});});
				setAllowedUsers(dbAllowedUsers);
			},
			(error) => {
				console.error('could not get allowed users: ', error);
			}
		);

		/* Update background */
		updateBackground('facilitator', gameId);


		return () => {
			unsubscribeUsers();	
			unsubscribeAllowedUsers();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Input email */
	const [email, setEmail] = useState(null);
	const updateEmail = (event) => {
		setEmail(event.target.value);
	};

	/**
	 * Add new allowed user 
	 */
	const handleAddAllowedUser = () => {
		if (email && email.length > 0) {
			const db = firebase.firestore();
			db.collection(appConfig.allowedUsersDbName).add({email:email}).then(() => {
				setEmail(null);
			});
		}
	};

	/**
	 * Remove allowed user
	 * @param {string} uid 
	 */
	const handleRemoveAllowedUser = (uid) => {
		const db = firebase.firestore();
		db.collection(appConfig.allowedUsersDbName).doc(uid).delete().then(() => {
			console.log('ok');
		});
	};

	/**
	 * Opens delete user warning
	 * @param {string} auser 
	 */
	const openDeleteUserWarning = (auser) => {
		setPopupData(auser);
	};

	/**
	 * Closes delete user warning
	 */
	const closeUserWarning = () => {
		setPopupData(null);
	};

	/**
	 * deletes user and removes them from allowed user list
	 * @param {string} email 
	 * @returns 
	 */
	const handleDeleteUser = (auser) => {

		return new Promise((resolve) => {
			apiHelper('admin/delete-user', {
				userEmail: auser.email
			}).then(() => {
				handleRemoveAllowedUser(auser.id);
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Handles delete user click, checking whether its only deleting allowed user, or also deleting actual user
	 * @param {bool} isCreated
	 * @param {object} auser contains user email and id.
	 */
	const handleOnDeleteUserClick = (isCreated, auser) => {
		console.log(auser);
		// If user is already created, we start deleting user as well
		if (isCreated) {
			openDeleteUserWarning(auser);
		} else {
			handleRemoveAllowedUser(auser.id);
		}
	};

	return (
		<div className="Admin">
			{popupData &&
				<PopupDeleteUser 
					deleteUser={handleDeleteUser} 
					userData={popupData} 
					closePopup={closeUserWarning} 
				/>
			}
			<div className="Admin-gameBtn">
				<Button 
					classes={['red', 'logout']} 
					text={adminUiTexts.backToGame} 
					onClick={() => {handleGoToPage('landing');}}
				/>
			</div>
			<div className="Admin-addUser">
				<div className="Admin-addUserInput">
					<input
						name="email"
						type="text"
						placeholder={adminUiTexts.email}
						value={email ? email : ''}
						onChange={(event) => {updateEmail(event);}}
					/>
				</div>
				<div className="Admin-addUserBtn">
					<Button 
						classes={['login']}
						text={adminUiTexts.addUser}
						onClick={() => {handleAddAllowedUser();}}
					/>
				</div>
			</div>
			<div className="Admin-users">
				<div className="Admin-usersHeader">
					<div className="Admin-usersItem">{adminUiTexts.email}</div>
					<div className="Admin-usersItem">{adminUiTexts.status}</div>
					<div className="Admin-usersItem delete">{adminUiTexts.delete}</div>
				</div>
				<div className="Admin-usersBody">
					{allowedUsers.map((auser) => {
						const isCreated = users.some((u) => {return u.email === auser.email;});
						return (
							<div key={auser.id} className="Admin-usersRow">
								<div className="Admin-usersItem">{auser.email}</div>
								<div className="Admin-usersItem">{(isCreated ? adminUiTexts.created : '-')}</div>
								<div className="Admin-usersItem delete">
									<Button 
										text={'X'}
										classes={['delete']}
										onClick={() => {
											handleOnDeleteUserClick(isCreated, auser);
										}}
									/>
								</div>
							</div>
						);
					})}

				</div>

			</div>
		</div>
	);
};

Admin.propTypes = {
	handleGoToPage: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default Admin;
