import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { facilitatorUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import Leaderboard from 'components/ui/leaderboard/leaderboard';
import './game-done.scss';
import { soundsData } from 'data/sound-data';

const GameDone = ({ groups, handleGoToPage, updateBackground, gameId, handlePlayMusic }) => {
	useEffect(() => {
		// Set background image id
		updateBackground('facilitator', gameId);
		handlePlayMusic(false, soundsData.login);
		
		return () => {
			handlePlayMusic(false, null);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Navigates back to landing page and stops the music
	const handleGoBack = () => {
		handlePlayMusic(false, null);
		handleGoToPage('landing');
	};

	return (
		<div className="GameDone">
			{/* Logo */}
			<div className="GameDone-logo" onClick={() => {handleGoToPage('landing');}} >
				<Logo gameId={gameId} />
			</div>
		
			{/* Leaderboard  */}
			<div className="GameDone-leaderBoard">
				<div className='GameDone-leaderBoardTitle'><span>{facilitatorUiTexts.gameDonePage.title}</span></div>
				<Leaderboard status="done" groups={groups} />
			</div>

			{/* Back to landing page btn */}
			<div className="GameDone-backBtn">
				<Button 
					classes={['blueLight', gameId, 'backBtn']}
					text={facilitatorUiTexts.back}
					onClick={() => {handleGoBack();}}
				/>
			</div>
			

		</div>
	);
};

GameDone.propTypes = {
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default GameDone;
