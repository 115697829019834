import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import { gameUiTexts } from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import Button from 'components/ui/button/button';
import PopupCaseFile from 'components/ui/popup-case-file/popup-case-file';
import './game.scss';

const Game = ({ game, group, groups, gameId, handleGoToPage, updateGroup, updateBackground }) => {
	/* Get game data (depends on gameId) */
	const gamePagesData = gamesData[gameId].gamePagesData;

	/* Current page and its data */
	const [pageId, setPageId] 	= useState('areaHub');
	const pageData 				= gamePagesData[pageId];
	let CurrentComponent 		= pageData.component; // areaHub, areas, cases, 
	const infoTitle 			= pageData.infoTitle;
	const infoText 				= pageData.infoText;
	const routes 				= pageData.routes;
	let id 						= null;
	const [casesId, setCasesId] = useState(null);

	/* if the current page is not areahub get id and possible case data */
	if (pageId !== 'areaHub') {
		id = pageData.id;
		if (casesId !== null) {
			CurrentComponent = pageData.caseComponent;
		}
	}

	useEffect(() => {
		// Set background image id
		updateBackground(pageData.background, gameId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageId]);
	
	/* Popup for case file */
	const [popupFileData, setPopupFileData] = useState(null);

	return (
		<div className="Game">
			<DndProvider options={HTML5toTouch}>
				{CurrentComponent && 
					<CurrentComponent 
						setPageId={setPageId}
						infoTitle={infoTitle}
						infoText={infoText}
						routes={routes}
						id={id}
						game={game}
						gameId={gameId}
						group={group}
						groups={groups}
						setCasesId={setCasesId}
						casesId={casesId}
						updateGroup={updateGroup}
						setPopupFileData={setPopupFileData}
					/>
				}

				{pageId === 'areaHub' &&
					<div className="Game-backBtn">
						<Button
							text={gameUiTexts.backBtn}
							classes={['backBtnGame', gameId, 'responsive']}
							onClick={() => {handleGoToPage('landing');}}
						/>
					</div>
				}
			</DndProvider>

			{popupFileData && <PopupCaseFile 
				popupData={popupFileData}
				setPopupData={setPopupFileData}
			/>}
		</div>
	);
};

Game.propTypes = {
	gameId: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired, 
	group: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
};

export default Game;