import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import { getCookie, setCookie } from 'helpers/cookie-helper';
import Login from './login';
import Loading from 'components/loading/loading';
import { loginUiTexts } from 'data/ui-texts';
import FacilitatorController from 'components/users/facilitator-controller';
import GroupController from 'components/users/group-controller';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			email: null,
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Check if logged in */
		this.checkIfLoggedIn();

		this.props.updateBackground('login', this.props.gameId);
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* Ok to cookies */
				const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
				if (!cookieConsent) {
					setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
				}

				const isFacilitator = user.email !== null;
				if (isFacilitator) {
					/* Logged in as facilitator */
					this.setState({
						isLoggedIn: true,
						isLoading: false,
						userId: user.uid,
						isFacilitator: isFacilitator,
						email: user.email
					});
				} else {
					/* Logged in as group */
					this.setState({isLoggedIn: true, isLoading: false, userId: user.uid});
				}
			} else {
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					isFacilitator: false,
					email: null,
				});
			}
		});	
	};



	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			email: null,
		});
	};

	/**
	 * Switch between facilitator / group login box
	 * @param {string} activeLoginBox 
	 */
	setActiveLoginBox = (activeLoginBox) => {
		this.setState({activeLoginBox});
	};

	/**
 	* Render component
 	*/
	render() {
		/* Waiting for loading done */
		if (this.state.isLoading) {
			return (
				<Loading
					loadErrMsg={loginUiTexts.loadingGame} 
					handleLogout={this.handleLogout}
				/>
			);
		}

		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<Login 
					gameId={this.props.gameId}
					activeLoginBox={this.state.activeLoginBox}
					setActiveLoginBox={this.setActiveLoginBox}
				/>
			);
		}

		/* User is logged in, get controller component for facilitator / group */
		let UserComponent = (this.state.isFacilitator ? FacilitatorController : GroupController);

		return (
			<UserComponent 
				gameId={this.props.gameId}
				userId={this.state.userId}
				handleLogout={this.handleLogout}
				updateBackground={this.props.updateBackground}
				handlePlayMusic={this.props.handlePlayMusic}
			/>
		);
	}
}

LoginController.propTypes = {
	gameId: PropTypes.string.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default LoginController;