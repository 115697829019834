import React from 'react';
import PropTypes from 'prop-types';
import {calculateCompletionData, getGroupPoints} from 'helpers/game-helper';
import AreaButton from 'components/ui/area-button/area-button';
import Info from 'components/ui/info/info';
import Logo from 'components/ui/logo/logo';
import InfoBar from 'components/ui/info-bar/info-bar';
import './area-hub.scss';
import { gamesData } from 'data/games-data';

const AreaHub = ({ group, infoTitle, infoText, routes, setPageId, gameId }) => {
	const groupName = group.name ? group.name : group.id;
	const groupPoints = getGroupPoints(group);
	
	return (
		<div className='AreaHub'>
			<div className='AreaHub-sideWrapper'>
				<div className='AreaHub-logo'><Logo gameId={gameId}/></div>
				<div className='AreaHub-infoWindow'>
					{(infoTitle && infoText) && 
						<Info 
							title={infoTitle} 
							text={infoText}
							gameId={gameId}
						/>
					}
				</div>
			</div>	
			
			<div className='AreaHub-middleWrapper'>
				<div className='AreaHub-header'>
					<InfoBar points={groupPoints} iconId='trophy' gameId={gameId}/>
					<div className='AreaHub-groupName'>
						<InfoBar text={groupName} iconId='person' gameId={gameId}/>
					</div>
				</div>
				<div className='AreaHub-areaWrapper'>
					<div className={'AreaHub-areaButtons ' + gameId}>
						{routes.map((route, index) => {
							const taskData = gamesData[gameId].tasksData[route.id];
							const completionData = calculateCompletionData(taskData, group.assignments);
							return (
								<div key={index} className={'AreaHub-areaButton-' + index}>
									<AreaButton
										isComplete={completionData.completedPercentage === 100}
										areaButtonId={route.id}
										name={route.name} 
										onClick={() => {setPageId(route.pageId);}}
										gameId={gameId}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

AreaHub.propTypes = {
	infoTitle: PropTypes.string.isRequired,
	infoText: PropTypes.string.isRequired,
	routes: PropTypes.array.isRequired,
	setPageId: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default AreaHub;