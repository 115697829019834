import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loginUiTexts, facilitatorUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Logo from '../ui/logo/logo';
import PopupRestartGame from 'components/ui/popup-restart-game/popup-restart-game';
import './landing-page-facilitator.scss';

const LandingPageFacilitator = ({ gameId, role, restartGame, handleGoToPage, handleLogout, updateBackground }) => {
	useEffect(() => {
		// Set background image id
		updateBackground('login', gameId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Confirm restart popup */
	const [showConfirmRestartPopup, setShowConfirmRestartPopup] = useState(false);

	return (
		<div className={'LandingPageFacilitator'}>
			<div className="LandingPageFacilitator-welcome">
				<span>{facilitatorUiTexts.landingPage.welcomeTo}</span>
			</div>
			<div className='LandingPageFacilitator-logoWrapper'>
				<Logo gameId={gameId} page="landing" />
			</div>
			<div className="LandingPageFacilitator-content">
				<div className='LandingPageFacilitator-title'>
					<span>{facilitatorUiTexts.landingPage.title}</span>
				</div>
				<div className='LandingPageFacilitator-text'>
					<span>{facilitatorUiTexts.landingPage.text}</span>
				</div>
				<div className='LandingPageFacilitator-buttons'>
					<div className='LandingPageFacilitator-newGame'>
						<Button 
							classes={['login']}
							text={facilitatorUiTexts.landingPage.newGame} 
							onClick={() => {setShowConfirmRestartPopup(true);}}
						/>
					</div>
					<div className='LandingPageFacilitator-continueGame'>
						<Button 
							classes={['login']}
							text={facilitatorUiTexts.landingPage.continueGame}
							onClick={() => {handleGoToPage('game');}}
						/>
					</div>
				</div>
			</div>

			{/* Logout button */}
			<div className="LandingPageFacilitator-logoutBtn">
				<Button 
					classes={['login']} 
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Admin button */}
			{role === 'admin' && <div className="LandingPageFacilitator-adminBtn">
				<Button 
					classes={['login']} 
					text={facilitatorUiTexts.landingPage.adminBtn}
					onClick={() => {handleGoToPage('admin');}}
				/>
			</div>}

			{/* Back to select game btn */}
			<div className="LandingPageFacilitator-backBtn">
				<Button 
					classes={['backBtnGame', gameId]} 
					text={facilitatorUiTexts.back}
					type="a"
					href="/"
				/>
			</div>
			
			{/* Confirm restart game popup */}
			{showConfirmRestartPopup && 
				<PopupRestartGame 
					restartGame={restartGame}
					handleGoToPage={handleGoToPage}
					closePopup={() => {setShowConfirmRestartPopup(false);}}
				/>
			}
		</div>
	);
};

LandingPageFacilitator.propTypes = {
	gameId: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	restartGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired
};

export default LandingPageFacilitator;
