const taskTemplates = require('../../task-templates');

const tasksData = [
	/* case 1 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'management-multiple-choice-goals',
		text: `Hvilke af disse synes I er gode mål for virksomheden at styre efter?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Overskuddet skal stige med 10 % hvert år de første tre år', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det første år skal vi sælge varer for kr. 2.000.000',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Vores kunder skal altid være tilfredse',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Der skal ansættes 1 elev indenfor 3 måneder',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Vi vil være de bedste til det vi laver',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'management-multiple-choice-swot',
		text: `Et nyttigt redskab for ledelsen kan være at lave en såkaldt SWOT-analyse. Hvad står det for?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Strengths, Weaknesses, Opportunities, Threats', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Stakes, Wins, Open doors, Trouble',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Speak With Open Tongues',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Sales, Work, Output, Trends',
			}),
		],
	}),
	/* case 2 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1A',
		taskId: 'management-multiple-choice-design-punishment',
		text: `Hvad kan straffen være, hvis man bliver dømt for designindgreb (som det hedder, hvis man har krænket andres design)?`,
		shuffleOptions: false,
		multiline: 'off',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'At betale erstatning', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'At tilbagekalde produktet fra handlen',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At tilintetgøre produktet',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'At betale en bøde',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Fængsel i op til 1,5 år',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Alle ovenstående',
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.5,
		lockedById: 'management-multiple-choice-design-punishment',
		title: 'Opgave 1B',
		taskId: 'similarities-multiple-choice-best-options',
		text: `Jeres jurister vurderer at jeres efterligning ligner originalen på rigtigt mange punkter. Hvad kan være fornuftige handlinger nu?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'At droppe produktionen', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'At kontakte Superdry og spørge om tilladelse',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At sætte produktionen i gang',
			}),
		],
	}),
	/* case 3 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'management-multiple-choice-bridge-accident',
		text: `I har netop læst, at der har været en stor ulykke på Storebæltsbroen. Den er spærret hele dagen, men I venter på en levering af varer, som skal over broen. Varerne bliver mindst 12 timer forsinket, så hvad er det rigtige at gøre? `,
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Forsøge at få åbnet broen',
				points: 0
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Lade lagermedarbejderne afspadsere om eftermiddagen',
				points: 100
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Prøve at finde en anden distributør i nærheden',
				points: 60
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'management-input-answer-flat-tire',
		text: `På vej til at afhente nye varer punkterer I på venstre fordæk i en af jeres varebiler. Den lokale mekaniker anbefaler, at I får skiftet begge fordæk, så deres slidbaner er ensartede. 
		Prisen for at skifte ét dæk er 1250 kr. (inkl. moms). Jeres virksomhed har dog ret til senere at få momsen retur, så hvad bliver prisen for begge dæk (ex. moms)?`,
		answer: 2000,
		unit: 'kr.',
		image: null
	}),
	/* case 4 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'management-multiple-choice-intern-talk',
		text: `Ungearbejderen har solgt 5 t-shirts til tilbudspris selvom de ikke var på tilbud. Hvad skal Salgschefen sige?`,
		popupText: 'Man skal altid holde en god tone med sine medarbejdere selvom de er længere ned i systemet.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Hvorfor gav du rabat? Du ved da godt, at det skal de her trøjer ikke have.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Hvad tænker du på?! Vi kan da ikke bare give rabat til højre og venstre!',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Hvis du ikke kan finde ud af systemerne for rabat, så overvej at finde et andet job.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'De her trøjer var slet ikke på tilbud. Vidste du det?',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'management-spot-errors-meeting',
		layout: 'management-meeting',
		text: `Direktøren gør klar til møde med bestyrelsen. Hvad er der galt på billedet? Tryk på fejlene. Tryk OK, når I tror, I har dem alle. `,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'messy-hair'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wrong-tie'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'shirt-neck'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'short-shirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'buttons'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'upside-down-smile'}),
		]
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'management-paper-doll-sales-manager',
		layout: 'management-sales-manager',
		avatarId: 'avatar-1',
		text: `Hvad skal Salgschefen have på når han skal snakke med butikkens medarbejdere?
		</br> Vælg, hvad I tror er det rigtige og tryk så OK.`,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'cigaret', title: 'Cigaret'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'keys', title: 'Nøglebundt'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'shorts', title: 'Shorts'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'goodsellers-polo', title: 'Goodsellers Polo', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'pda', title: 'PDA'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'name-tag', title: 'Skilt', isCorrect: true
			}),
			
		]
	}),


	/* Case 5 */
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1,
		taskId: 'management-sort-jobs',
		title: 'Opgave 1A',
		text: `Hvilke jobfunktioner kan vores ansatte søge i andre virksomheder?`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'can-apply', 
				title: `Kan søge`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'cannot-apply', 
				title: `Kan IKKE søge`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Indkøber`,
				categoryIds: ['can-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Marketingmedarbejder`,
				categoryIds: ['can-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Leder`,
				categoryIds: ['can-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Sælger`,
				categoryIds: ['can-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Sekretær`,
				categoryIds: ['can-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: `Receptionist`,
				categoryIds: ['can-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, 
				text: `Ingeniør`,
				categoryIds: ['cannot-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 8, 
				text: `Advokat`,
				categoryIds: ['cannot-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 9, 
				text: `Lærer`,
				categoryIds: ['cannot-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 10, 
				text: `Revisor`,
				categoryIds: ['cannot-apply']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 11, 
				text: `Elektriker`,
				categoryIds: ['cannot-apply']
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1.5,
		taskId: 'management-order-department',
		lockedById: 'management-sort-jobs',
		title: 'Opgave 1B',
		text: `Hvilke afdelinger i Goodsellers er størst? Sorter dem fra flest til færrest ansatte. `,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: `Salgsafdelingen`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: `Indkøbsafdelingen`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: `Marketingsafdelingen`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: `IT afdelingen`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, 
				text: `Ledelsen`,
				correctOrderNumbers: [5]
			})
		]
	}),
	Object.assign({}, taskTemplates.pairsTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 2,
		taskId: 'management-sort-pay-average',
		title: 'Opgave 2',
		text: `Hvad er gennemsnitslønnen for virksomhedens afdelinger? Dan par mellem månedsløn og afdeling.`,
		columnAItems: [
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '36k',
				text: `36.807 kr.`,
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '39k',
				text: '39.493 kr.'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '34k',
				text: ` 34.295 kr.`
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '38k',
				text: '38.950 kr.'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '44k',
				text: '44.500 kr.'
			}),
		],
		columnBItems: [
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'Marketing',
				text: 'Marketing',
				connectedToItemId: '36k'

			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'shopper',
				text: 'Indkøber',
				connectedToItemId: '39k'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'salesperson',
				text: 'Sælger',
				connectedToItemId: '34k'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'IT employee',
				text: 'IT medarbejder',
				connectedToItemId: '38k'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'economy',
				text: 'Økonomi',
				connectedToItemId: '44k'
			}),
			 
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'management-spot-errors-office',
		layout: 'management-office',
		text: `Der er noget galt her. Tryk på fejlene. Tryk OK, når I mener, I har dem alle.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'messy-papers'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sitting-on-table'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'open-drawers'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trophy'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dog'}),
			// Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'books'}),
		]
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'hhxedu-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};