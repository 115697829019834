import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {inputAnswerMaxAttempts} from 'data/points-data';
import Button from 'components/ui/button/button';
import TaskIntro from 'components/game-engines/task-intro/task-intro';
import './input-answer.scss';

const InputAnswer = (props) => {
	const {
		isCompleted, 
		attemptNumber, 
		playerTaskData, 
		taskData, 
		setPopupFileData, 
		handleUpdateTask, 
		handleCompleteTask,
		gameId
	} = props;

	/* Task is paused */
	const [isPaused, setIsPaused] = useState(false);

	/* Answer */
	const [answer, setAnswer] = useState('');

	/* Track if answer is correct or not */
	const [answerIsCorrect, setAnswerIsCorrect] = useState((null));

	/**
	 * Updates input value
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		let value = event.target.value;
		setAnswer(value);
		setAnswerIsCorrect(null);
	};

	/**
	 * Confirm input answer
	 * @param {string} answer
	 */
	const handleConfirmInput = (event) => {
		if (event) event.preventDefault();

		if (!answer || answer.length === 0) return;

		setIsPaused(true);
		
		/* Check if correct */
		const isCorrect = checkIfCorrect(answer);

		/* Feedback */
		setAnswerIsCorrect(isCorrect);

		/* Complete / update task */
		if (isCorrect || attemptNumber >= inputAnswerMaxAttempts) {
			handleCompleteTask(
				'input-answer',
				{answer: answer, errors: (isCorrect ? 0 : 1)}
			);
		} else {
			handleUpdateTask(
				'input-answer',
				{answer: answer, errors: (isCorrect ? 0 : 1)}
			);
			setIsPaused(false);
		}
	};

	/* Update if new task */
	useEffect(() => {
		setIsPaused(false);
		const answer = (playerTaskData && playerTaskData.answer ? playerTaskData.answer : '');
		setAnswer(answer);
		const answerIsCorrect = (playerTaskData && playerTaskData.answer 
			? checkIfCorrect(playerTaskData.answer) : null);
		setAnswerIsCorrect(answerIsCorrect);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Checks if answer is correct, either within range if subtype range, or if equal to answer.
	 * @param {number} inputAnswer 
	 * @returns 
	 */
	const checkIfCorrect = (inputAnswer) => {
		let formattedAnswer = inputAnswer.replace('.', '');
		formattedAnswer = parseFloat(formattedAnswer.replace(',', '.'));
		if (taskData.subtype === 'range') {
			return formattedAnswer >= taskData.range.min && formattedAnswer <= taskData.range.max;
		}
		return formattedAnswer === taskData.answer;
	};

	return (
		<div className={'InputAnswer ' + (taskData.layout ? ' ' + taskData.layout : '')}
		>
			{taskData.text && <div className="InputAnswer-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
					files={taskData.files}
					setPopupFileData={setPopupFileData}
					gameId={gameId}
				/>
			</div>}
			<form className='InputAnswer-form' onSubmit={handleConfirmInput}>
				<div className={'InputAnswer-input' + 
					(isCompleted ? ' disabled' : '') + 
					(answerIsCorrect !== null 
						? answerIsCorrect ? ' correct' : ' wrong' 
						: ''
					)
				}>
					<input
						disabled={isCompleted || isPaused}
						name='answer'
						type='text'
						placeholder={''}
						autoComplete='section-inputAnswer answer'
						value={answer}
						onChange={(event)=>{handleInput(event);}}
					/>
					{taskData.unit && renderMarkdown(taskData.unit)}
				</div>
				{!isCompleted && <div className="InputAnswer-answerButton">
					<Button
						isDisabled={isCompleted}
						text={gameUiTexts.handOver}
						classes={['confirmTask', gameId]}
						onClick={() => {handleConfirmInput();}}
					/>
				</div>}
				{(isCompleted && !answerIsCorrect) && 
					<div className="InputAnswer-correctAnswer">
						{renderMarkdown(gameUiTexts.correctAnswer + ': ' + taskData.answer + ' ' + taskData.unit)}
					</div>}
			</form>
		</div>
	);
};

InputAnswer.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	attemptNumber: PropTypes.number.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	setPopupFileData: PropTypes.func.isRequired,
	handleUpdateTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	gameId: PropTypes.string.isRequired,
};

export default InputAnswer;
