import React from 'react';
import PropTypes from 'prop-types';
import { facilitatorUiTexts } from 'data/ui-texts';
import './game-code.scss';

const gameCode = ({game}) => {
	return (
		<div className="GameCode">
			<span>{facilitatorUiTexts.gameCode}:</span>
			<span>{game.id}</span>
		</div>
	);
};


gameCode.propTypes = {
	game: PropTypes.object.isRequired,
};

export default gameCode;
