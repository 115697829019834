import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { facilitatorUiTexts } from 'data/ui-texts';
import { soundsData } from 'data/sound-data';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import PopupGameStatusWarning from 'components/ui/popup-game-status-warning/popup-game-status-warning';
import GameCode from 'components/ui/game-code/game-code';
import './game-ready.scss';

const GameReady = ({ game, groups, updateGame, handleGoToPage, updateBackground, handlePlayMusic }) => {
	useEffect(() => {
		// Set background image id
		updateBackground('facilitator', game.gameId);
		handlePlayMusic(true, soundsData.login);

		return () => {
			handlePlayMusic(false, null);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/* Track if is starting (updating) game */
	const [isStartingGame, setIsStartingGame] = useState(false);

	/* Track if show/hide warning popup */
	const [showStartGameWarningPopup, setShowStartGameWarningPopup] = useState(false);

	/* Track the amount of placeholders to display */
	const placeholders =  (groups && groups.length < 12) ? [...Array(12 - groups.length)] : [];

	/**
	 * Start game
	 */
	const handleStartGame = () => {
		if (groups.length === 0) {
			/* No groups logged in, show warning */
			setShowStartGameWarningPopup(true);
		} else {
			/* 1 or more groups logged in, update game status */
			setIsStartingGame(true);
			const startTime = Date.now();
			updateGame({status: 'open', startTime}).catch((error) => {
				console.error(error);
				setIsStartingGame(false);
			});
		}
	};

	
	
	return (
		<div className="GameReady">
			{/* Logo */}
			<div className="GameReady-logo" onClick={() => {handleGoToPage('landing');}} >
				<Logo gameId={game.gameId} />
			</div>

			{/* Game code */}
			<GameCode game={game}/>
			
			{/* Groups title */}
			<div className="GameReady-title">
				<span>{facilitatorUiTexts.gameReadyPage.group}:</span>
			</div>

			{/* Logged in groups */}
			<div className="GameReady-groups">
				{groups.map((group, index) => {
					return (
						<div key={index} className={'GameReady-group ' + (group.isPlaying ? 'active' : '')}>
							<div className={'GameReady-groupName ' + game.gameId}>
								<span>{group.name ? group.name : group.id}</span>
							</div>
						</div>
					);
				})}
				{placeholders.map((item, index) => {
					return (
						<div key={index} className={'GameReady-group '}></div>
					);
				})}
			</div>


			{/* Start game btn */}
			<div className="GameReady-startBtn">
				<Button 
					isLoading={isStartingGame}
					classes={['blueLight', game.gameId]}
					text={facilitatorUiTexts.startGameBtn}
					onClick={handleStartGame}
				/>
			</div>

			{/* Warning popup (no groups logged in) */}
			{showStartGameWarningPopup &&
				<PopupGameStatusWarning 
					newStatus='open'
					additionalGameState={{startTime: Date.now()}}
					updateGame={updateGame}
					closePopup={() => {setShowStartGameWarningPopup(false);}}
				/>}
		</div>
	);
};

GameReady.propTypes = {
	game: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	updateGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default GameReady;
